import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer";
import Header from "../Header";
import { Form, Input, Label, FormGroup, Button } from "reactstrap";
import { Col, Container, Row } from "react-bootstrap";
import Axios from "axios";
import LoadingBox from "../LoadingBox";
import MessageBox from "../MessageBox";
import { innovationContestContact } from "../../actions/innovationcontestActions";

export default function InnovationContestScreen(props) {
  // const userSignin = useSelector((state) => state.userSignin);
  // const { userInfo } = userSignin;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState([]);

  const [nameErr, setNameErr] = useState({});
  const [emailErr, setEmailErr] = useState({});
  const [telephoneErr, setTelephoneErr] = useState({});
  const [messageErr, setMessageErr] = useState({});

  const formValidation = () => {
    const nameErr = {};
    const emailErr = {};
    const telephoneErr = {};
    const messageErr = {};

    let isValid = true;

    if (name === "") {
      nameErr.nameEmpty = "name can not be blank.";
      isValid = false;
    }
    if (name.trim().length > 20) {
      nameErr.nameLong = "name text is too long.";
      isValid = false;
    }
    if (email === "") {
      emailErr.emailEmpty = "email can not be blank.";
      isValid = false;
    }
    if (
      telephone.trim().length <= 9 ||
      telephone.trim().length > 10 ||
      telephone === ""
    ) {
      telephoneErr.telephoneLong = "length of a phone number is 10 digits.";
      isValid = false;
    }
    if (message === "") {
      messageErr.messageEmpty = "ideas can not be blank.";
      isValid = false;
    }
    if (message.trim().length > 300) {
      messageErr.messageLong = "ideas text is too long.";
      isValid = false;
    }

    setNameErr(nameErr);
    setEmailErr(emailErr);
    setTelephoneErr(telephoneErr);
    setMessageErr(messageErr);

    return isValid;
  };

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      dispatch(
        innovationContestContact(name, telephone, email, message, image)
      );
      setName("");
      setTelephone("");
      setEmail("");
      setMessage("");
      setImage("");

      props.history.push("/thankyou");
    }
  };

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState("");

  const uploadFileHandler = async (e) => {
    const file = e.target.files;
    const bodyFormData = new FormData();

    for (let i = 0; i < file.length; i++) {
      bodyFormData.append("image", file[i]);
    }

    setLoadingUpload(true);
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API}/uploadss/s3`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      setImage(data);
      setLoadingUpload(false);
    } catch (error) {
      setErrorUpload(error.message);
      setLoadingUpload(false);
    }
  };

  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN backgroundcolor-f2f2f2 overflow-hidden">
        <div className="CenterBody">
          <Row className="p-2">
            <Col xs={12} md={6} data-aos="fade-in">
              <div style={{textAlign:"center"}}>
              <img
                src="https://i.postimg.cc/kXLJ5SH6/p2.jpg"
                style={{ width: "300px" }}
              ></img>
              </div>
              <span style={{ fontWeight: "bold" }}>Idea</span>
              <br />
              Hey, here we are with some exciting stuff!
              <br />
              We all know that the world is growing so fast and we are lagging
              behind because we are not able to match our intelligence with the
              never ending technology transformation intelligence.
              <br />
              So as an organisation we though why not to collect our challenges
              or need/demand to make life more convenience.
              <br />
              We are inviting all of you to register an Idea which you wants to
              share with us and you think of this is the thing that if its there
              in the market will help you a and others.So we are giving you
              platform to lock your ideas whatever you feel and then based on
              the selection by technical expert we are providing exciting prizes
              to the selected ideas and people.
              <br />
              Idea can be anything there is no restriction, you can put whatever
              you wants its not necessarily belongs yo IT or some what It can be
              as general as buying fruits from market. Truly We believ NO IDEA
              IS SMALL IDEA OR BAD IDEA.We will definitely turn your idea into
              reality and will help people.
              <br />
              Please lock your ideas by providing your input or{" "}
              <span style={{ fontWeight: "bold" }}>
                You can share your ideas with our company email address{" "}
                <span style={{ color: "blue" }}>amabzeindia@gmail.com</span> or
                you can directly contact with us{" "}
                <span style={{ color: "blue" }}>
                  +91 8088340845 or +91 7758802331
                </span>
                .
              </span>
              <br />
            </Col>
            <Col xs={12} md={6}>
              <Form onSubmit={submitHandler}>
                {/* {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger">{error}</MessageBox>} */}
                <FormGroup>
                  <Label className="Label" for="name" data-aos="fade-in">
                    Full Name
                  </Label>
                  <Input
                    placeholder="Enter Full Name"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    data-aos="fade-right"
                  />
                  {Object.keys(nameErr).map((key) => {
                    return (
                      <div style={{ color: "red" }} key={key}>
                        {nameErr[key]}
                      </div>
                    );
                  })}
                </FormGroup>

                <FormGroup>
                  <Label className="Label" for="telephone" data-aos="fade-in">
                    Mobile Number
                  </Label>
                  <Input
                    type="number"
                    placeholder="Enter Mobile Number"
                    name="telephone"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                    data-aos="fade-left"
                  />
                  {Object.keys(telephoneErr).map((key) => {
                    return (
                      <div style={{ color: "red" }} key={key}>
                        {telephoneErr[key]}
                      </div>
                    );
                  })}
                </FormGroup>

                <FormGroup>
                  <Label className="Label" for="email" data-aos="fade-in">
                    Email
                  </Label>
                  <Input
                    type="email"
                    placeholder="name@example.com"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    data-aos="fade-right"
                  />
                  {Object.keys(emailErr).map((key) => {
                    return (
                      <div style={{ color: "red" }} key={key}>
                        {emailErr[key]}
                      </div>
                    );
                  })}
                </FormGroup>

                <FormGroup>
                  <Label className="Label" for="textarea" data-aos="fade-in">
                    Share Ideas
                  </Label>
                  <Input
                    placeholder="Share Ideas..."
                    type="textarea"
                    name="textarea"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ height: 220 }}
                    data-aos="fade-left"
                  />
                  {Object.keys(messageErr).map((key) => {
                    return (
                      <div style={{ color: "red" }} key={key}>
                        {messageErr[key]}
                      </div>
                    );
                  })}
                </FormGroup>

                <div>
                  <label htmlFor="imageFile" className="Label">
                    Upload Files
                  </label>
                  <input
                    className="UserAccountform-input"
                    type="file"
                    id="imageFile"
                    label="Choose Image"
                    onChange={uploadFileHandler}
                    multiple
                  ></input>
                  <p style={{ color: "green" }}>
                    You are only allowed to upload a maximum of 10 files at a
                    single time.
                  </p>
                  {loadingUpload && <LoadingBox></LoadingBox>}
                  {errorUpload && (
                    <MessageBox variant="danger">{errorUpload}</MessageBox>
                  )}
                </div>

                {loadingUpload === true ? null : (
                  <Button type="submit">Submit</Button>
                )}
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
}
