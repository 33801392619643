import React from "react";
import "./TimelineCompany.css";

export default function TimelineCompany() {
  return (
    <div className="overflow-hidden">
    

      <ul className="timeline">
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag">THE BEGINNING</span>
              <span className="time-wrapper">
                <span
                  className="time"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                >
                  2018
                </span>
              </span>
            </div>
            <div
              className="desc"
              style={{ textAlign: "center" }}
              data-aos="fade-left"
              data-aos-duration="1800"
            >
              Started the journey as a Sole proprietorship with a android
              project in Karnataka.
            </div>
          </div>
        </li>

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag">PVT LTD REGISTRATION</span>
              <span className="time-wrapper">
                <span
                  className="time"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                >
                  2019
                </span>
              </span>
            </div>
            <div
              className="desc  "
              style={{ textAlign: "center" }}
              data-aos="fade-right"
              data-aos-duration="1800"
            >
              Registered the company as Pvt Ltd and acquired many clients across
              India.
            </div>
          </div>
        </li>

        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag">ENTERED INTO AI SECTOR</span>
              <span className="time-wrapper">
                <span
                  className="time"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                >
                  2020
                </span>
              </span>
            </div>
            <div
              className="desc  "
              style={{ textAlign: "center" }}
              data-aos="fade-left"
              data-aos-duration="1800"
            >
              Secured projects into AI Sector and worked on exciting AI
              projects.
            </div>
          </div>
        </li>

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="hexa"></span>
              <span className="flag">EXPLORING NEW OPPORTUNITIES</span>
              <span className="time-wrapper">
                <span
                  className="time"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                >
                  2021
                </span>
              </span>
            </div>
            <div
              className="desc  "
              style={{ textAlign: "center" }}
              data-aos="fade-right"
              data-aos-duration="1800"
            >
              We are working for with many exciting projects, extending our
              reach to Android, Web and Machine Learning , stay tuned for
              further updates.
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
