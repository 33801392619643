import {
  JOBAPPLYDELETE_CONTACTUS_FAIL,
  JOBAPPLYDELETE_CONTACTUS_REQUEST,
  JOBAPPLYDELETE_CONTACTUS_SUCCESS,
  JOBAPPLYLIST_CONTACTUS_FAIL,
  JOBAPPLYLIST_CONTACTUS_REQUEST,
  JOBAPPLYLIST_CONTACTUS_SUCCESS,
} from "../constants/jobApplyConstants";
import Axios from "axios";

export const listJobApply =
  ({}) =>
  async (dispatch, getState) => {
    dispatch({ type: JOBAPPLYLIST_CONTACTUS_REQUEST });
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await Axios.get(
        // `/api/jobsapply`
        `${process.env.REACT_APP_API}/jobsapply`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      dispatch({ type: JOBAPPLYLIST_CONTACTUS_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: JOBAPPLYLIST_CONTACTUS_FAIL, payload: message });
    }
  };

export const deleteJobApply = (userId) => async (dispatch, getState) => {
  dispatch({ type: JOBAPPLYDELETE_CONTACTUS_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.delete(
      // `/api/jobsapply/${userId}`
      `${process.env.REACT_APP_API}/jobsapply/${userId}`,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({ type: JOBAPPLYDELETE_CONTACTUS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: JOBAPPLYDELETE_CONTACTUS_FAIL, payload: message });
  }
};
