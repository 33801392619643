import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Card } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listJobs } from "../../actions/jobActions";
import LoadingBox from "../LoadingBox";
import MessageBox from "../MessageBox";
import { Link } from "react-router-dom";

export default function CurrentOpning() {
  const dispatch = useDispatch();
  const jobList = useSelector((state) => state.jobList);
  const { loading, error, products } = jobList;

  useEffect(() => {
    dispatch(listJobs());
  }, [dispatch]);

  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN backgroundcolor-f2f2f2">
        <div className="InternshipScreen-header">
          <Image
            src="https://i.postimg.cc/bwtsz4bL/currentopning.webp"
            className="InternshipScreen-headerImg"
            data-aos="fade-up"
          ></Image>
          <div className="InternshipScreen-headerText">
            <h1
              style={{ fontWeight: "600", fontSize: "70px" }}
              data-aos="fade-down"
            >
              Current Opening
            </h1>
          </div>
        </div>

        <div className="CenterBody " style={{ padding: "10px 0" }}>
          <h6 style={{ padding: "30px 10px", fontWeight: "600" }}>
            COVID-19 update: The safety and well-being of our candidates, our
            people and their families continues to be a top priority. Until
            travel restrictions change, interviews will continue to be conducted
            virtually. See how we embrace change and drive innovative solutions,
            every day.
          </h6>

          <div className="CurrentOpningCardContainer">
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <>
                {products.length > 0 ? (
                  products.map((m) => (
                    <Card
                      style={{ width: "18rem" }}
                      className="CurrentOpningCardContainer-Card"
                      key={m._id}
                      data-aos="fade-in"
                    >
                      <Card.Body>
                        <Card.Title className="CurrentOpningCardContainer-CardTitle">
                          {m.name}
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          Software
                        </Card.Subtitle>
                        <Card.Text className="hyphens-auto">
                          {m.description.slice(0, 150)}
                          <span style={{ color: "blue" }}>
                            <Link as={Link} to={`/job/${m._id}`}>
                              ...more
                            </Link>
                          </span>
                        </Card.Text>
                        <Card.Link
                          as={Link}
                          to={`/job/${m._id}`}
                          className="CurrentOpningCardContainer-CardLink"
                        >
                          Apply
                        </Card.Link>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <h5 style={{ color: "red" }}>No Data Found</h5>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
