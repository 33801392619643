import React from "react";
import CardScreen from "./CardScreen";
// import Typical from "react-typical";
import BlogCards from "./BlogCards";
import AboutScreen from "./AboutScreen";
import ClientReviewSlider from "./ClientReviewSlider";
import Header from "../Header";
import Footer from "../Footer";
import Demo from "./Demo";
import ServicesTech from "./ServicesTech";
import BlogCardsForHomeScreen from "./BlogCardsForHomeScreen";
import ClientLogoSlider from "./ClientLogoSlider";

export default function HomeScreen() {
  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN">
        <div className="HomeScreenAboutUsContainer CenterBody">
          <div
            className="HomeScreenAboutUsContainer-left content"
            style={{ textAlign: "initial" }}
          >
            <h1 style={{fontWeight:"bolder"}}>Build Better Software</h1>
            <p style={{ fontWeight: "600" }}>
              We’ve developed a better approach to custom Software development.
            </p>

            <a className="btn" href="/service-product">
              More Details
            </a>
          </div>
          <div className="HomeScreenAboutUsContainer-right">
            <img src="images/undraw1.svg" className="feature-img"></img>
          </div>
        </div>

        <div style={{ backgroundColor: "#f2f2f2" }}>
          <div className="infoSection overflow-hidden ">
            <CardScreen />
          </div>

          <div style={{ textAlign: "center", padding: "30px 0" }}>
            <span className="text3D">Blogs</span>
          </div>
          <BlogCardsForHomeScreen />

          <div style={{ textAlign: "center", padding: "30px 0 60px 0" }}>
            <span className="text3D">Clients reviews</span>
          </div>
          <ClientReviewSlider />

          <Demo />
          <ServicesTech />
          <div style={{ textAlign: "center", padding: "30px 0" }}>
            <span className="text3D">Our Clients</span>
          </div>
          <ClientLogoSlider />
        </div>
      </div>
      <Footer />
    </div>
  );
}
