import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import TimelineCompany from "./TimelineCompany"

export default function WhyAmabze() {
  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN backgroundcolor-f2f2f2">
          <TimelineCompany />
      </div>
      <Footer />
    </div>
  );
}
