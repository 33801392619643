import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import {
  productCreateReducer,
  productDeleteReducer,
  productDetailsReducer,
  productListReducer,
  productUpdateReducer,
} from "./reducers/blogReducers";
import {
  deleteContactmeUserReducer,
  listContactmeUsersReducer,
} from "./reducers/contactmeReducers";

import {
  deleteContactusUserReducer,
  listContactusUsersReducer,
  userContactusReducer,
} from "./reducers/contactusReducers";
import { deleteInnovationContestUserReducer, listInnovationContestUsersReducer } from "./reducers/innovationcontestReducers";
import { deleteJobApplyReducer, listJobApplyReducer } from "./reducers/jobApplyReducers";
import {
  applyJobReducer,
  jobCreateReducer,
  jobDeleteReducer,
  jobDetailsReducer,
  jobListReducer,
  jobUpdateReducer,
} from "./reducers/jobReducers";
import { userSigninReducer } from "./reducers/userReducers";

const initialState = {
  userSignin: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
};

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userContactus: userContactusReducer,
  contactusList: listContactusUsersReducer,
  contactusDelete: deleteContactusUserReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  contactmeList: listContactmeUsersReducer,
  contactmeDelete: deleteContactmeUserReducer,

  jobList: jobListReducer,
  jobDetails: jobDetailsReducer,
  jobCreate: jobCreateReducer,
  jobUpdate: jobUpdateReducer,
  jobDelete: jobDeleteReducer,

  //JobApply
  userApplyJob: applyJobReducer,
  userApplyJobList: listJobApplyReducer,
  userApplyJobDelete: deleteJobApplyReducer,

  innovationContestUsersList: listInnovationContestUsersReducer,
  innovationContestUsersDelete: deleteInnovationContestUserReducer,


});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
