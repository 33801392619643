import React from "react";

export default function ServicesTech() {
  return (
    <>
      <div style={{ textAlign: "center", padding: "30px 0" }}>
        <span className="text3D">OUR EXPERTISE</span>
      </div>

      <div
        className="servicesTech-row1Container"
        style={{ textAlign: "center" }}
        data-aos="fade-up"
      >
        <div className="servicesTech-box servicesTech-boxDown positionR">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <h2>Web Development</h2>
          <p data-aos="fade-in">
            Web design is both an art and a science. Working with you to
            understand your target audience, we can create a web design that
            will accomplish both goals.
          </p>
          <img
            src="images/webdevelopment_f2f2f2.svg"
            alt=""
            className="servicesTech-img"
          />
        </div>

        <div className="servicesTech-box positionR" data-aos="fade-up">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <h2>Apps Development</h2>
          <p data-aos="fade-in">
            We develop for range of platforms (iOS, Android & Windows) but build
            the app native for each of them to ensure the best user experience
            on each platform with our team of passionate developers.
          </p>
          <img
            src="images/mobiledevelopment_f2f2f2.svg"
            alt=""
            className="servicesTech-img"
          />
        </div>

        <div
          className="servicesTech-box servicesTech-boxDown positionR"
          data-aos="fade-up"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <h2>Artificial Intelligence</h2>
          <p data-aos="fade-in">
            We provide a state-of-art online management and administration
            system using ML and AI. We are one of the few organizations using
            AI. We are implementing Deep Learning tools.
          </p>
          <img
            src="images/artificialintelligence.svg"
            alt=""
            className="servicesTech-img"
          />
        </div>
      </div>

      <div
        className="servicesTech-row2Container"
        style={{ textAlign: "center" }}
      >
        <div className="servicesTech-box  positionR" data-aos="fade-up">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <h2>Machine Learning</h2>
          <p data-aos="fade-in">
            We develop applications that can turn any device into a powerful
            content augmentation platform, using basic and affordable hardware.
          </p>
          <img src="images/ml.svg" alt="" className="servicesTech-img" />
        </div>
      </div>

      <div className="showmore" style={{ textAlign: "center" }}>
        <a href="/service-product">
          <h6>show more</h6>
          <h3>ᗐ</h3>
        </a>
      </div>
    </>
  );
}
