export const INNOVATIONCONTEST_REQUEST = "INNOVATIONCONTEST_REQUEST";
export const INNOVATIONCONTEST_SUCCESS = "INNOVATIONCONTEST_SUCCESS";
export const INNOVATIONCONTEST_FAIL = "INNOVATIONCONTEST_FAIL";

export const USERLIST_INNOVATIONCONTEST_REQUEST = "USERLIST_INNOVATIONCONTEST_REQUEST";
export const USERLIST_INNOVATIONCONTEST_SUCCESS = "USERLIST_INNOVATIONCONTEST_SUCCESS";
export const USERLIST_INNOVATIONCONTEST_FAIL = "USERLIST_INNOVATIONCONTEST_FAIL";

export const USERDELETE_INNOVATIONCONTEST_REQUEST = "USERDELETE_INNOVATIONCONTEST_REQUEST";
export const USERDELETE_INNOVATIONCONTEST_SUCCESS = "USERDELETE_INNOVATIONCONTEST_SUCCESS";
export const USERDELETE_INNOVATIONCONTEST_FAIL = "USERDELETE_INNOVATIONCONTEST_FAIL";
export const USERDELETE_INNOVATIONCONTEST_RESET = "USERDELETE_INNOVATIONCONTEST_RESET";
