import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Label, FormGroup, Button } from "reactstrap";
import { applyJob } from "../../actions/jobActions";

export default function ApplyForm(props) {
  const productId = props.jobID;
  const [jobTitle, setJobTitle] = useState(props.jobTitle);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [college, setCollege] = useState("");
  const [year, setYear] = useState("");

  const [nameErr, setNameErr] = useState({});
  const [emailErr, setEmailErr] = useState({});
  const [telephoneErr, setTelephoneErr] = useState({});
  const [collegeErr, setCollegeErr] = useState({});
  const [yearErr, setYearErr] = useState({});

  const formValidation = () => {
    const nameErr = {};
    const emailErr = {};
    const telephoneErr = {};
    const collegeErr = {};
    const yearErr = {};
    let isValid = true;

    if (name === "") {
      nameErr.nameEmpty = "name can not be blank.";
      isValid = false;
    }
    if (name.trim().length > 20) {
      nameErr.nameLong = "name text is too long.";
      isValid = false;
    }
    if (email === "") {
      emailErr.emailEmpty = "email can not be blank.";
      isValid = false;
    }
    if (
      telephone.trim().length <= 9 ||
      telephone.trim().length > 10 ||
      telephone === ""
    ) {
      telephoneErr.telephoneLong = "length of a phone number is 10 digits.";
      isValid = false;
    }
    if (college === "") {
      collegeErr.messageEmpty = "college can not be blank.";
      isValid = false;
    }
    if (college.trim().length > 40) {
      collegeErr.messageLong = "college text is too long.";
      isValid = false;
    }
    if (year === "") {
      yearErr.messageEmpty = "year can not be blank.";
      isValid = false;
    }

    setNameErr(nameErr);
    setEmailErr(emailErr);
    setTelephoneErr(telephoneErr);
    setCollegeErr(collegeErr);
    setYearErr(yearErr);
    return isValid;
  };

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      dispatch(
        applyJob(name, telephone, email, college, year, jobTitle, productId)
      );
      setName("");
      setTelephone("");
      setEmail("");
      setCollege("");
      setYear("");

      alert("Thank you");
    }
  };

  return (
    <>
      <Form
        style={{ padding: "10px" }}
        onSubmit={submitHandler}
        className="boxShadow5"
        data-aos="fade-down"
        data-aos-delay="200"
      >
        <FormGroup>
          <Label className="Label" for="name">
            Job Title
          </Label>
          <Input
            placeholder="Enter Full Name"
            type="text"
            name="name"
            disabled
            value={jobTitle}
          />
        </FormGroup>

        <FormGroup>
          <Label className="Label" for="name">
            Full Name
          </Label>
          <Input
            placeholder="Enter Full Name"
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {Object.keys(nameErr).map((key) => {
            return (
              <div style={{ color: "red" }} key={key}>
                {nameErr[key]}
              </div>
            );
          })}
        </FormGroup>

        <FormGroup>
          <Label className="Label" for="telephone">
            Telephone
          </Label>
          <Input
            type="number"
            placeholder="Enter Telephone"
            name="telephone"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
          />
          {Object.keys(telephoneErr).map((key) => {
            return (
              <div style={{ color: "red" }} key={key}>
                {telephoneErr[key]}
              </div>
            );
          })}
        </FormGroup>

        <FormGroup>
          <Label className="Label" for="email">
            Email
          </Label>
          <Input
            type="email"
            placeholder="name@example.com"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {Object.keys(emailErr).map((key) => {
            return (
              <div style={{ color: "red" }} key={key}>
                {emailErr[key]}
              </div>
            );
          })}
        </FormGroup>

        <FormGroup className="FormGroup">
          <Label className="Label" for="email">
            College
          </Label>
          <Input
            type="college"
            placeholder="enter your college name"
            name="college"
            value={college}
            onChange={(e) => setCollege(e.target.value)}
          />
          {Object.keys(collegeErr).map((key) => {
            return (
              <div style={{ color: "red" }} key={key}>
                {collegeErr[key]}
              </div>
            );
          })}
        </FormGroup>

        <FormGroup className="FormGroup">
          <Label className="Label" for="year">
            Year
          </Label>
          <Input
            type="year"
            placeholder="enter year"
            name="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
          {Object.keys(yearErr).map((key) => {
            return (
              <div style={{ color: "red" }} key={key}>
                {yearErr[key]}
              </div>
            );
          })}
        </FormGroup>

        <Button type="submit">Submit</Button>
      </Form>
    </>
  );
}
