import React from "react";

export default function CompanyDetails() {
  return (
    <section className="company-hero">
      <div className="company-content">
        <h2>WHO ARE WE&nbsp;?</h2>
        <p data-aos="fade-up">
          Amabze is a company which provides an extensive range of services such
          as Machine Learning , Artificial Intelligence , Internet of Things ,
          mobile app development, and most importantly digital marketing to take
          our client’s business to an all new level and publicize it. We have
          gathered a talented team of transcendent level technical writers, web
          designers, developers, marketers, communication specialists, project
          managers and business developers to make our service excel from every
          aspect. We all brainstorm on the projects and our consolidated ideas
          give a different dimension to it.
        </p>
        <h2>OUR MISSION</h2>
        <p data-aos="fade-up">
          Our mission is to be the top-notch provider of Web Applications,
          products, services and solutions across the globe that enable and
          transform the way our customers gather, manage, distribute and
          communicate information. Our mission statement is the commitment
          towards our clients which we will always adhere to. We strive to make
          technology and our results integrate smoothly with your business so
          your business can prosper. Our main goal is to provide the most
          innovative, reliable and cost effective services to our customers. All
          you have to do is collaborate with us, and we will deliver you with
          the best solution for your project right at your desktop.
        </p>
      </div>
      <div className="company-waves"></div>
    </section>
  );
}
