export const USER_CONTACTME_REQUEST = "USER_CONTACTME_REQUEST";
export const USER_CONTACTME_SUCCESS = "USER_CONTACTME_SUCCESS";
export const USER_CONTACTME_FAIL = "USER_CONTACTME_FAIL";

export const USERLIST_CONTACTME_REQUEST = "USERLIST_CONTACTME_REQUEST";
export const USERLIST_CONTACTME_SUCCESS = "USERLIST_CONTACTME_SUCCESS";
export const USERLIST_CONTACTME_FAIL = "USERLIST_CONTACTME_FAIL";

export const USERDELETE_CONTACTME_REQUEST = "USERDELETE_CONTACTME_REQUEST";
export const USERDELETE_CONTACTME_SUCCESS = "USERDELETE_CONTACTME_SUCCESS";
export const USERDELETE_CONTACTME_FAIL = "USERDELETE_CONTACTME_FAIL";
export const USERDELETE_CONTACTME_RESET = "USERDELETE_CONTACTME_RESET";
