import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import data from "../data";

export default function Gallery() {
  return (
    <div className="SCREEN-CONTAINER">
      <Header />

      <div className="MAIN">
        <div className="CenterBody">
          <Container fluid style={{ textAlign: "center", margin:"20px 0" }}>
            <Row noGutters className="gallerys">
              {data.gallery.map((img) => (
                <Col xs={12} md={4} key={img._id} data-aos="fade-in" style={{padding:"5px"}}>
                  <a href={img.image} target="_blank">
                    <img className="GalleryImage" src={img.image}></img>
                  </a>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
}
