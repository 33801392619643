import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Label, FormGroup, Button } from "reactstrap";
import Header from "../Header";
import Footer from "../Footer";
import { contactme } from "../../actions/contactmeAction";

export default function ContactMeScreen(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");

  const [nameErr, setNameErr] = useState({});
  const [emailErr, setEmailErr] = useState({});
  const [telephoneErr, setTelephoneErr] = useState({});
  const [messageErr, setMessageErr] = useState({});

  const formValidation = () => {
    const nameErr = {};
    const emailErr = {};
    const telephoneErr = {};
    const messageErr = {};

    let isValid = true;

    if (name === "") {
      nameErr.nameEmpty = "name can not be blank.";
      isValid = false;
    }
    if (name.trim().length > 20) {
      nameErr.nameLong = "name text is too long.";
      isValid = false;
    }
    if (email === "") {
      emailErr.emailEmpty = "email can not be blank.";
      isValid = false;
    }
    if (
      telephone.trim().length <= 9 ||
      telephone.trim().length > 10 ||
      telephone === ""
    ) {
      telephoneErr.telephoneLong = "length of a phone number is 10 digits.";
      isValid = false;
    }
    if (message === "") {
      messageErr.messageEmpty = "message can not be blank.";
      isValid = false;
    }
    if (message.trim().length > 300) {
      messageErr.messageLong = "message text is too long.";
      isValid = false;
    }

    setNameErr(nameErr);
    setEmailErr(emailErr);
    setTelephoneErr(telephoneErr);
    setMessageErr(messageErr);

    return isValid;
  };

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      dispatch(contactme(name, telephone, email, message));
      setName("");
      setTelephone("");
      setEmail("");
      setMessage("");

      props.history.push("/thankyou");
    }
  };

  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN backgroundcolor-f2f2f2">
        <Form onSubmit={submitHandler} className="ContactFormContainer">
          {/* {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger">{error}</MessageBox>} */}
          <FormGroup>
            <Label className="Label" for="name"  data-aos="fade-in">
              Full Name
            </Label>
            <Input
              placeholder="Enter Full Name"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              data-aos="fade-right"
            />
            {Object.keys(nameErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {nameErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <FormGroup>
            <Label className="Label" for="telephone" data-aos="fade-in">
              Telephone
            </Label>
            <Input
              type="number"
              placeholder="Enter Telephone"
              name="telephone"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              data-aos="fade-left"
            />
            {Object.keys(telephoneErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {telephoneErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <FormGroup>
            <Label className="Label" for="email" data-aos="fade-in">
              Email
            </Label>
            <Input
              type="email"
              placeholder="name@example.com"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              data-aos="fade-right"
            />
            {Object.keys(emailErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {emailErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <FormGroup>
            <Label className="Label" for="textarea" data-aos="fade-in">
              Message
            </Label>
            <Input
              placeholder="Enter Message"
              type="textarea"
              name="textarea"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              style={{ height: 220 }}
              data-aos="fade-left"
            />
            {Object.keys(messageErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {messageErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <Button type="submit">
            Submit
          </Button>
        </Form>
      </div>
      <Footer />
    </div>
  );
}
