import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsProduct } from "../../actions/blogActions";
import Header from "../Header";
import Footer from "../Footer";
import MessageBox from "../MessageBox";
import LoadingBox from "../LoadingBox";

export default function BlogScreen(props) {
  const dispatch = useDispatch();
  const productId = props.match.params.id;
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  useEffect(() => {
    dispatch(detailsProduct(productId));
  }, [dispatch, productId]);
  return (
    <div className="SCREEN-CONTAINER">
      <Header />

      <div
        className="MAIN backgroundcolor-f2f2f2"
        style={{ textAlign: "center" }}
      >
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div>
            <h2 className="MAIN-BOX_TITLE">{product.name}</h2>

            {product.image === "tech1.webp" ? (
              <img
                src={`/images/${product.image}`}
                alt=""
                className="responsiveImg"
                data-aos="fade-up"
              />
            ) : (
              <img src={product.image} alt="" className="responsiveImg"  data-aos="fade-up" />
            )}

            {product.description ? <p className="CenterBody">{product.description}</p> : null}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
