import {
  USERDELETE_CONTACTME_FAIL,
  USERDELETE_CONTACTME_REQUEST,
  USERDELETE_CONTACTME_RESET,
  USERDELETE_CONTACTME_SUCCESS,
  USERLIST_CONTACTME_FAIL,
  USERLIST_CONTACTME_REQUEST,
  USERLIST_CONTACTME_SUCCESS,
  USER_CONTACTME_FAIL,
  USER_CONTACTME_REQUEST,
  USER_CONTACTME_SUCCESS,
} from "../constants/contactmeConstants";

export const userContactmeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CONTACTME_REQUEST:
      return { loading: true };
    case USER_CONTACTME_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_CONTACTME_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listContactmeUsersReducer = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case USERLIST_CONTACTME_REQUEST:
      return { loading: true };
    case USERLIST_CONTACTME_SUCCESS:
      return { loading: false, users: action.payload };
    case USERLIST_CONTACTME_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteContactmeUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USERDELETE_CONTACTME_REQUEST:
      return { loading: true };
    case USERDELETE_CONTACTME_SUCCESS:
      return { loading: false, success: true };
    case USERDELETE_CONTACTME_FAIL:
      return { loading: false, error: action.payload };
    case USERDELETE_CONTACTME_RESET:
      return {};
    default:
      return state;
  }
};
