import {
  INNOVATIONCONTEST_FAIL,
  INNOVATIONCONTEST_REQUEST,
  INNOVATIONCONTEST_SUCCESS,
  USERDELETE_INNOVATIONCONTEST_FAIL,
  USERDELETE_INNOVATIONCONTEST_REQUEST,
  USERDELETE_INNOVATIONCONTEST_RESET,
  USERDELETE_INNOVATIONCONTEST_SUCCESS,
  USERLIST_INNOVATIONCONTEST_FAIL,
  USERLIST_INNOVATIONCONTEST_REQUEST,
  USERLIST_INNOVATIONCONTEST_SUCCESS,
} from "../constants/innovationcontestConstants";

export const userInnovationContestReducer = (state = {}, action) => {
  switch (action.type) {
    case INNOVATIONCONTEST_REQUEST:
      return { loading: true };
    case INNOVATIONCONTEST_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case INNOVATIONCONTEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listInnovationContestUsersReducer = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case USERLIST_INNOVATIONCONTEST_REQUEST:
      return { loading: true };
    case USERLIST_INNOVATIONCONTEST_SUCCESS:
      return { loading: false, users: action.payload };
    case USERLIST_INNOVATIONCONTEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteInnovationContestUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USERDELETE_INNOVATIONCONTEST_REQUEST:
      return { loading: true };
    case USERDELETE_INNOVATIONCONTEST_SUCCESS:
      return { loading: false, success: true };
    case USERDELETE_INNOVATIONCONTEST_FAIL:
      return { loading: false, error: action.payload };
    case USERDELETE_INNOVATIONCONTEST_RESET:
      return {};
    default:
      return state;
  }
};
