import React from "react";
import data from "../data";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "./styles.css";
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/core";
import ClientReview from "./ClientReview";

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

export default function ClientReviewSlider() {
  SwiperCore.use([Autoplay]);
  return (
    <div className="container">
      <Swiper
        navigation={true}
        effect={"coverflow"}
        centeredSlides={true}
        slidesPerView={window.innerWidth < 768 ? 1 : "auto"}
        loop={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        className="mySwiper"
        autoplay={{ delay: 3000 }}
      >
        {data.clientReviewSlider.map((m) => (
          <SwiperSlide key={m._id}>
            <ClientReview
              review={m.review}
              image={m.image}
              clientName={m.clientName}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
