import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Image } from "react-bootstrap";
import Table from "./Table"
import { Link } from "react-router-dom";

export default function InternshipScreen() {
  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN backgroundcolor-f2f2f2">
        <div className="InternshipScreen-header">
          <Image
            src="https://i.postimg.cc/0N1rrZ0v/work.webp"
            className="InternshipScreen-headerImg"
            data-aos="fade-up"
          ></Image>
          <div className="InternshipScreen-headerText">
            <h1
              style={{ fontWeight: "600", fontSize: "70px" }}
              data-aos="fade-down"
            >
              Internship
            </h1>
          </div>
        </div>

        <div className="CenterBody " style={{ padding: "10px 0" }}>
          <h6 style={{ padding: "30px 10px", fontWeight: "600"}}>
            COVID-19 update: The safety and well-being of our candidates, our
            people and their families continues to be a top priority. Until
            travel restrictions change, interviews will continue to be conducted
            virtually. See how we embrace change and drive innovative solutions,
            every day.
          </h6>
         <Table />
         <div className="MenuBtnContainer" >
            <Link to="/contactus">
              <button className="MenuBtn" style={{fontSize:"20px", fontWeight:"600"}}>Apply Now</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
