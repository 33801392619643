import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Header from "../Header";
import Footer from "../Footer";

export default function WhatWeDo() {
  return (
    <div className="SCREEN-CONTAINER overflow-hidden">
      <Header />
      <div className="MAIN backgroundcolor-f2f2f2">
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            // date="2011 - present"
            iconStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            icon={<i className="fas fa-project-diagram"></i>}
          >
            <h3 className="vertical-timeline-element-title" style={{fontWeight:"600"}}>
              PROJECT DISCOVERY
            </h3>
            {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
            <p>
              <i className="fas fa-chevron-right"></i> In-depth research of the
              field, market pool, and target population.
            </p>
            <p>
              <i className="fas fa-chevron-right"></i> Fine tuning the technological
              route for the design and development of the project.
            </p>
            <p>
              <i className="fas fa-chevron-right"></i> Determining the full and
              final in-depth scope of the project.
            </p>
            <p>
              <i className="fas fa-chevron-right"></i> Creation of Software
              Requirement Specification.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            // date="2010 - 2011"
            iconStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            // icon={<WorkIcon />}
            icon={<i className="fas fa-drafting-compass"></i>}
          >
            <h3 className="vertical-timeline-element-title"  style={{fontWeight:"600"}}>
              WIREFRAMING & ARCHITECTURE
            </h3>
            {/* <h4 className="vertical-timeline-element-subtitle">
            San Francisco, CA
          </h4> */}
            <p>
              <i className="fas fa-chevron-right"></i>&nbsp; Conception and creation
              of the graphic elements of the application, including color
              scheme, atmosphere, general look and feel, etc.
            </p>
            <p>
              <i className="fas fa-chevron-right"></i>&nbsp; Actual design of the
              application based on the layouts and structures.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            // date="2008 - 2010"
            iconStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            //   icon={<WorkIcon />}
            icon={<i className="fas fa-laptop-code"></i>}
          >
            <h3 className="vertical-timeline-element-title"  style={{fontWeight:"600"}}>
              FRONT END PROGRAMMING
            </h3>
            {/* <h4 className="vertical-timeline-element-subtitle">
            Los Angeles, CA
          </h4> */}
            <p>
              <i className="fas fa-chevron-right"></i>&nbsp; Advanced programming
              and integration of latest front-end technologies for enhanced user
              experience and usability based on the wireframes.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            // date="2006 - 2008"
            iconStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            //   icon={<WorkIcon />}
            icon={<i className="fas fa-server"></i>}
          >
            <h3 className="vertical-timeline-element-title"  style={{fontWeight:"600"}}>
              SERVER SIDE DEVELOPMENT
            </h3>
            {/* <h4 className="vertical-timeline-element-subtitle">
            San Francisco, CA
          </h4> */}
            <p>
              <i className="fas fa-chevron-right"></i>&nbsp; Integration of the
              databases.
            </p>
            <p>
              <i className="fas fa-chevron-right"></i>&nbsp; Actual programming work
              to implement the back-end and full administration.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            // date="April 2013"
            iconStyle={{
              // background: "rgb(233, 30, 99)",
              background: "rgb(33, 150, 243)",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            //   icon={<SchoolIcon />}
            icon={<i className="fas fa-virus-slash"></i>}
          >
            <h3 className="vertical-timeline-element-title"  style={{fontWeight:"600"}}>
              QUALITY ASSURANCE
            </h3>
            {/* <h4 className="vertical-timeline-element-subtitle">Online Course</h4> */}
            <p>
              <i className="fas fa-chevron-right"></i>&nbsp; Extensive testing to
              ensure the application is 100% bug free and is fully compatible.
            </p>
            <p>
              <i className="fas fa-chevron-right"></i>&nbsp; Fine tuning the final
              details of the application design and features.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            // date="November 2012"
            iconStyle={{
              // background: "rgb(233, 30, 99)",
              background: "rgb(33, 150, 243)",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            //   icon={<SchoolIcon />}
            icon={<i className="fas fa-globe"></i>}
          >
            <h3 className="vertical-timeline-element-title"  style={{fontWeight:"600"}}>GO LIVE</h3>
            {/* <h4 className="vertical-timeline-element-subtitle">Certification</h4> */}
            <p>
              <i className="fas fa-chevron-right"></i>&nbsp; Migration of the
              application to the live server and launch!
            </p>
          </VerticalTimelineElement>

          {/* <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          // icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Bachelor of Science in Interactive Digital Media Visual Imaging
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Bachelor Degree
          </h4>
          <p>Creative Direction, Visual Design</p>
        </VerticalTimelineElement> */}

          {/* <VerticalTimelineElement
          iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
          // icon={<StarIcon />}
        /> */}
        </VerticalTimeline>
      </div>
      <Footer />
    </div>
  );
}
