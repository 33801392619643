const ImageDataLink = {
  CoreTeam: [
    {
      _id: "1",
      image: "https://i.postimg.cc/63Cbgqgr/CW1-1.jpg",
      name: "Sanjay Singhal",
      work: "CA",
    },
    {
      _id: "2",
      image: "https://i.postimg.cc/CxF5TJY6/CW2.jpg",
      name: "Prachi Singh",
      work: "RPA Developer",
    },
    {
      _id: "3",
      image: "https://i.postimg.cc/TP1PhwKV/CW3.jpg",
      name: "Pankaj Kumar",
      work: "Full stack Developer",
    },
    {
      _id: "4",
      image: "https://i.postimg.cc/T1GN6GvZ/CW4-1.jpg",
      name: "Anand Kumar",
      work: "Android Developer",
    },
    {
      _id: "5",
      image: "https://i.postimg.cc/kGc1MZnD/CW5-1.jpg",
      name: "Nitish Kumar",
      work: "Java Developer",
    },{
        _id: "6",
        image: "https://i.postimg.cc/3RkcJKG5/d2p2.jpg",
        name: "Suvam Sahoo",
        work: "Full stack Developer",
      },
  ],
};

export default ImageDataLink;
