import Axios from "axios";
import {
  INNOVATIONCONTEST_FAIL,
  INNOVATIONCONTEST_REQUEST,
  INNOVATIONCONTEST_SUCCESS,
  USERDELETE_INNOVATIONCONTEST_FAIL,
  USERDELETE_INNOVATIONCONTEST_REQUEST,
  USERDELETE_INNOVATIONCONTEST_SUCCESS,
  USERLIST_INNOVATIONCONTEST_FAIL,
  USERLIST_INNOVATIONCONTEST_REQUEST,
  USERLIST_INNOVATIONCONTEST_SUCCESS,
} from "../constants/innovationcontestConstants";

export const innovationContestContact =
  (name, telephone, email, message, image) => async (dispatch) => {
    dispatch({
      type: INNOVATIONCONTEST_REQUEST,
      payload: { name, telephone, email, message, image },
    });
    try {
      const { data } = await Axios.post(
        // "/api/contactme/contactme"
        `${process.env.REACT_APP_API}/innovationcontest/innovationcontest`,
        {
          name,
          telephone,
          email,
          message,
          image,
        }
      );
      dispatch({ type: INNOVATIONCONTEST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: INNOVATIONCONTEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listInnovationContest =
  ({}) =>
  async (dispatch, getState) => {
    dispatch({ type: USERLIST_INNOVATIONCONTEST_REQUEST });
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await Axios.get(
        // `/api/contactme`
        `${process.env.REACT_APP_API}/innovationcontest`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      dispatch({ type: USERLIST_INNOVATIONCONTEST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USERLIST_INNOVATIONCONTEST_FAIL, payload: message });
    }
  };

export const deleteInnovationContest =
  (userId) => async (dispatch, getState) => {
    dispatch({ type: USERDELETE_INNOVATIONCONTEST_REQUEST, payload: userId });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.delete(
        // `/api/contactme/${userId}`
        `${process.env.REACT_APP_API}/innovationcontest/${userId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: USERDELETE_INNOVATIONCONTEST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USERDELETE_INNOVATIONCONTEST_FAIL, payload: message });
    }
  };
