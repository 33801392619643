import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import MessageBox from "../LoadingBox";
import LoadingBox from "../LoadingBox";
import {
  deleteInnovationContest,
  listInnovationContest,
} from "../../actions/innovationcontestActions";

export default function InnovationContestAdminScreen() {
  const innovationContestUsersList = useSelector(
    (state) => state.innovationContestUsersList
  );
  const { loading, error, users } = innovationContestUsersList;
  const innovationContestUsersDelete = useSelector(
    (state) => state.innovationContestUsersDelete
  );
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = innovationContestUsersDelete;

  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listInnovationContest({}));
  }, [dispatch, successDelete]);

  const deleteHandler = (user) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteInnovationContest(user._id));
      setSearchTerm("");
    }
  };

  return (
    <div className="SCREEN-CONTAINER">
      <Header />

      <div
        className="MAIN backgroundcolor-f2f2f2"
        style={{ textAlign: "center" }}
      >
        <h1 style={{ padding: "20px 5px", fontWeight: "600" }}>
          Innovation Contest List
        </h1>

        {loadingDelete && <LoadingBox></LoadingBox>}
        {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
        {successDelete && (
          <MessageBox variant="success">User Deleted Successfully</MessageBox>
        )}

        <div className="searchBox">
          <input
            type="input"
            placeholder="Search data"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          ></input>
        </div>

        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div className="scrollTableH">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NAME</th>
                  <th>Telephone</th>
                  <th>EMAIL</th>
                  <th>Message</th>
                  <th>FILES</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {users
                  .filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      val.email
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      val.telephone
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      val._id.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((user) => (
                    <tr key={user._id}>
                      <td>{user._id}</td>
                      <td>{user.name}</td>
                      <td>{user.telephone}</td>
                      <td>{user.email}</td>
                      <td>{user.message}</td>
                      <td>
                        {user.image.map((pro) => (
                          <a href={pro.location} key={pro.location}>
                            {pro.location}
                          </a>
                        ))}
                      </td>
                      <td>
                        <button
                          className="adminButton"
                          type="button"
                          onClick={() => deleteHandler(user)}
                        >
                          Delete <i className="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
