import {
    JOBAPPLYDELETE_CONTACTUS_FAIL,
    JOBAPPLYDELETE_CONTACTUS_REQUEST,
  JOBAPPLYDELETE_CONTACTUS_RESET,
  JOBAPPLYDELETE_CONTACTUS_SUCCESS,
  JOBAPPLYLIST_CONTACTUS_FAIL,
  JOBAPPLYLIST_CONTACTUS_REQUEST,
  JOBAPPLYLIST_CONTACTUS_SUCCESS,
} from "../constants/jobApplyConstants";

export const listJobApplyReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case JOBAPPLYLIST_CONTACTUS_REQUEST:
      return { loading: true };
    case JOBAPPLYLIST_CONTACTUS_SUCCESS:
      return { loading: false, users: action.payload };
    case JOBAPPLYLIST_CONTACTUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteJobApplyReducer = (state = {}, action) => {
  switch (action.type) {
    case JOBAPPLYDELETE_CONTACTUS_REQUEST:
      return { loading: true };
    case JOBAPPLYDELETE_CONTACTUS_SUCCESS:
      return { loading: false, success: true };
    case JOBAPPLYDELETE_CONTACTUS_FAIL:
      return { loading: false, error: action.payload };
    case JOBAPPLYDELETE_CONTACTUS_RESET:
      return {};
    default:
      return state;
  }
};
