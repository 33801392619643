import React from "react";
import data from "../data";

export default function Table() {
  return (
    <div className="InternshipTableBody">
      <table className="InternshipTableBody-table">
        <thead className="InternshipTableBody-thead">
          <tr className="InternshipTableBody-tr">
            <th className="InternshipTableBody-th">Language</th>
            <th className="InternshipTableBody-th">Time Duration</th>
            <th className="InternshipTableBody-th">Course Code</th>
          </tr>
        </thead>
        <tbody className="InternshipTableBody-tbody">
          {data.internshipTable.map((i) => (
            <tr className="InternshipTableBody-tr" key={i._id}>
              <td className="InternshipTableBody-td">{i.Language}</td>
              <td className="InternshipTableBody-td">{i.TimeDuration}</td>
              <td className="InternshipTableBody-td">{i.CourseCode}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
