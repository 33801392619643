import Axios from "axios";
import {
  JOBAPPLY_CONTACTUS_FAIL,
  JOBAPPLY_CONTACTUS_REQUEST,
  JOBAPPLY_CONTACTUS_SUCCESS,
  JOB_CREATE_FAIL,
  JOB_CREATE_REQUEST,
  JOB_CREATE_SUCCESS,
  JOB_DELETE_FAIL,
  JOB_DELETE_REQUEST,
  JOB_DELETE_SUCCESS,
  JOB_DETAILS_FAIL,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_SUCCESS,
  JOB_LIST_FAIL,
  JOB_LIST_REQUEST,
  JOB_LIST_SUCCESS,
  JOB_UPDATE_FAIL,
  JOB_UPDATE_REQUEST,
  JOB_UPDATE_SUCCESS,
} from "../constants/jobConstants";

export const listJobs = () => async (dispatch) => {
  dispatch({
    type: JOB_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      // "/api/jobs"
      `${process.env.REACT_APP_API}/jobs`
    );
    dispatch({ type: JOB_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: JOB_LIST_FAIL, payload: error.message });
  }
};

export const detailsJob = (productId) => async (dispatch) => {
  dispatch({ type: JOB_DETAILS_REQUEST, payload: productId });
  try {
    const { data } = await Axios.get(
      //`/api/jobs/${productId}`
      `${process.env.REACT_APP_API}/jobs/${productId}`
    );
    dispatch({ type: JOB_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: JOB_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createJob = () => async (dispatch, getState) => {
  dispatch({ type: JOB_CREATE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.post(
      // "/api/jobs"
      `${process.env.REACT_APP_API}/jobs`,
      {},
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({
      type: JOB_CREATE_SUCCESS,
      payload: data.product,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: JOB_CREATE_FAIL, payload: message });
  }
};

export const updateJob = (product) => async (dispatch, getState) => {
  dispatch({ type: JOB_UPDATE_REQUEST, payload: product });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(
      // `/api/jobs/${product._id}`
      `${process.env.REACT_APP_API}/jobs/${product._id}`,
      product,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({ type: JOB_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: JOB_UPDATE_FAIL, error: message });
  }
};

export const deleteJob = (productId) => async (dispatch, getState) => {
  dispatch({ type: JOB_DELETE_REQUEST, payload: productId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = Axios.delete(
      // `/api/jobs/${productId}`
      `${process.env.REACT_APP_API}/jobs/${productId}`,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({ type: JOB_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: JOB_DELETE_FAIL, payload: message });
  }
};

//---------------------------------------------------------------------------JobApply
export const applyJob =
  (name, telephone, email, college, year, jobTitle, productId) =>
  async (dispatch) => {
    dispatch({
      type: JOBAPPLY_CONTACTUS_REQUEST,
      payload: { name, telephone, email, college, year, jobTitle, productId },
    });
    try {
      const { data } = await Axios.post(
        // `/api/jobs/${productId}`
        `${process.env.REACT_APP_API}/jobs/${productId}`,
        {
          name,
          telephone,
          email,
          college,
          year,
          jobTitle,
        }
      );
      dispatch({ type: JOBAPPLY_CONTACTUS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: JOBAPPLY_CONTACTUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
//---------------------------------------------------------------------------
