import React from "react";

export default function CardScreen() {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div style={{ padding: "30px 0" }}>
          <span className="text3D">OUR VISION</span>
        </div>
        <p
          style={{
            fontSize: "20px",
            padding: "20px 10px 60px 10px",
            fontWeight: "600",
          }}
        >
          We aim to become the ultimate revolutionary company in the world of
          Artificial Intelligence and Machine Learning across the globe. We have
          three main optimum vision goals which will make us finest and a
          leading company.
        </p>
      </div>

      <div className="infoCards">
        <div className="card one" data-aos="fade-up" data-aos-duration="1000">
          <img
            src="images/efficiency.svg"
            className="cardoneImg elements "
            alt=""
          />
          <div className="cardbgone"></div>

          <div className="cardContent">
            <h2>EFFICIENCY</h2>
            <p className="hyphens-auto ">
              Our hardworking and highly energetic team will work around your
              project and will try to improvise it as far as possible. We assure
              you to maximize our ability to meet the needs of the fast
              developing technology around the world. We strive to make our
              ideas integrate seamlessly with your business.
            </p>
          </div>
        </div>

        <div className="card two" data-aos="fade-up" data-aos-duration="1300">
          <img
            src="images/reliability.svg"
            className="cardtwoImg elements"
            alt=""
          />
          <div className="cardbgtwo"></div>

          <div className="cardContent">
            <h2>RELIABILITY</h2>
            <p className="hyphens-auto ">
              We intend to build a certain relationship with our clients which
              are a great combination of professionalism, friendliness and most
              importantly trust. It will help us to communicate openly about the
              project so that the result favors their business.
            </p>
          </div>
        </div>

        <div className="card three" data-aos="fade-up" data-aos-duration="1600">
          <img
            src="images/economin.svg"
            className="cardthreeImg elements"
            alt=""
          />
          <div className="cardbgone"></div>
          <div className="cardContent">
            <h2>ECONOMIC</h2>
            <p className="hyphens-auto ">
              We understand the fact that clients always look for “value for
              money” service and we here at Knowlexon keep up with the client’s
              expectation. Right from the start we keep up with open
              communication, cooperation, and collaboration with you — to ensure
              you get just what you need and want, at a price you can afford.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
