import Axios from "axios";
import {
  USERDELETE_CONTACTME_FAIL,
  USERDELETE_CONTACTME_REQUEST,
  USERDELETE_CONTACTME_SUCCESS,
  USERLIST_CONTACTME_FAIL,
  USERLIST_CONTACTME_REQUEST,
  USERLIST_CONTACTME_SUCCESS,
  USER_CONTACTME_FAIL,
  USER_CONTACTME_REQUEST,
  USER_CONTACTME_SUCCESS,
} from "../constants/contactmeConstants";

export const contactme =
  (name, telephone, email, message) => async (dispatch) => {
    dispatch({
      type: USER_CONTACTME_REQUEST,
      payload: { name, telephone, email, message },
    });
    try {
      const { data } = await Axios.post(
        // "/api/contactme/contactme"
        `${process.env.REACT_APP_API}/contactme/contactme`,
        {
          name,
          telephone,
          email,
          message,
        }
      );
      dispatch({ type: USER_CONTACTME_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: USER_CONTACTME_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listContactmeUsers =
  ({}) =>
  async (dispatch, getState) => {
    dispatch({ type: USERLIST_CONTACTME_REQUEST });
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await Axios.get(
        // `/api/contactme`
        `${process.env.REACT_APP_API}/contactme`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      dispatch({ type: USERLIST_CONTACTME_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USERLIST_CONTACTME_FAIL, payload: message });
    }
  };

export const deleteContactmeUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USERDELETE_CONTACTME_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.delete(
      // `/api/contactme/${userId}`
      `${process.env.REACT_APP_API}/contactme/${userId}`,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({ type: USERDELETE_CONTACTME_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USERDELETE_CONTACTME_FAIL, payload: message });
  }
};
