import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Label, FormGroup, Button } from "reactstrap";
import { FormControl } from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { contactus } from "../../actions/contactusAction";

export default function InternshipContactUsScreen(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [college, setCollege] = useState("");
  const [courcecode, setCourceCode] = useState("");
  const [year, setYear] = useState("");
  const [refName, setRefName] = useState("");

  const [nameErr, setNameErr] = useState({});
  const [emailErr, setEmailErr] = useState({});
  const [telephoneErr, setTelephoneErr] = useState({});
  const [collegeErr, setCollegeErr] = useState({});
  const [courcecodeErr, setCourceCodeErr] = useState({});
  const [yearErr, setYearErr] = useState({});
  const [refNameErr, setRefNameErr] = useState({});

  const formValidation = () => {
    const nameErr = {};
    const emailErr = {};
    const telephoneErr = {};
    const collegeErr = {};
    const courcecodeErr = {};
    const yearErr = {};
    const refNameErr = {};
    let isValid = true;

    if (name === "") {
      nameErr.nameEmpty = "name can not be blank.";
      isValid = false;
    }
    if (name.trim().length > 20) {
      nameErr.nameLong = "name text is too long.";
      isValid = false;
    }
    if (email === "") {
      emailErr.emailEmpty = "email can not be blank.";
      isValid = false;
    }
    if (
      telephone.trim().length <= 9 ||
      telephone.trim().length > 10 ||
      telephone === ""
    ) {
      telephoneErr.telephoneLong = "length of a phone number is 10 digits.";
      isValid = false;
    }
    if (college === "") {
      collegeErr.messageEmpty = "college can not be blank.";
      isValid = false;
    }
    if (college.trim().length > 40) {
      collegeErr.messageLong = "college text is too long.";
      isValid = false;
    }

    if (courcecode === "") {
      courcecodeErr.eventEmpty = "select a cource code";
      isValid = false;
    }
    if (courcecode === "select") {
      courcecodeErr.eventEmpty = "select a cource code";
      isValid = false;
    }

    setNameErr(nameErr);
    setEmailErr(emailErr);
    setTelephoneErr(telephoneErr);
    setCollegeErr(collegeErr);
    setCourceCodeErr(courcecodeErr);
    setYearErr(yearErr);
    setRefNameErr(refNameErr);
    return isValid;
  };

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      dispatch(
        contactus(name, telephone, email, college, courcecode, year, refName)
      );
      setName("");
      setTelephone("");
      setEmail("");
      setCourceCode("");
      setCollege("");
      setYear("");
      setRefName("");

      // props.history.push("/");
      props.history.push("/thankyou");
    }
  };
  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN backgroundcolor-f2f2f2">
        <Form onSubmit={submitHandler} className="ContactFormContainer">
          {/* {loading && <LoadingBox></LoadingBox>}
          {error && <MessageBox variant="danger">{error}</MessageBox>} */}
          <FormGroup>
            <Label className="Label" for="name">
              Full Name
            </Label>
            <Input
              placeholder="Enter Full Name"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {Object.keys(nameErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {nameErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <FormGroup>
            <Label className="Label" for="telephone">
              Telephone
            </Label>
            <Input
              type="number"
              placeholder="Enter Telephone"
              name="telephone"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
            />
            {Object.keys(telephoneErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {telephoneErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <FormGroup>
            <Label className="Label" for="email">
              Email
            </Label>
            <Input
              type="email"
              placeholder="name@example.com"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {Object.keys(emailErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {emailErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <FormGroup controlid="formGridState">
            <Label className="Label">Course Code</Label>
            <FormControl
              as="select"
              name="event"
              value={courcecode}
              onChange={(e) => setCourceCode(e.target.value)}
            >
              <option>select</option>
              <option>AMABZE01</option>
              <option>AMABZE02</option>
              <option>AMABZE03</option>
              <option>AMABZE04</option>
              <option>AMABZE05</option>
              <option>AMABZE06</option>
              <option>AMABZE07</option>
              <option>AMABZE08</option>
              <option>AMABZE09</option>
              <option>AMABZE10</option>
            </FormControl>
            {Object.keys(courcecodeErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {courcecodeErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <FormGroup className="FormGroup">
            <Label className="Label" for="email">
              College
            </Label>
            <Input
              type="college"
              placeholder="enter your college name"
              name="college"
              value={college}
              onChange={(e) => setCollege(e.target.value)}
            />
            {Object.keys(collegeErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {collegeErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <FormGroup className="FormGroup">
            <Label className="Label" for="year">
              Year
            </Label>
            <Input
              type="year"
              placeholder="enter year"
              name="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
            {Object.keys(yearErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {yearErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <FormGroup className="FormGroup">
            <Label className="Label" for="email">
              Reference Person
            </Label>
            <Input
              type="refName"
              placeholder="enter reference person name"
              name="refName"
              value={refName}
              onChange={(e) => setRefName(e.target.value)}
            />
            {Object.keys(refNameErr).map((key) => {
              return (
                <div style={{ color: "red" }} key={key}>
                  {refNameErr[key]}
                </div>
              );
            })}
          </FormGroup>

          <Button type="submit">Submit</Button>
        </Form>
      </div>
      <Footer />
    </div>
  );
}
