import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";

import "./styles.css";

// import Swiper core and required modules
import SwiperCore, { EffectCoverflow, Pagination, Autoplay } from "swiper/core";

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination]);

export default function ClientLogoSlider() {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <Swiper
        effect={"coverflow"}
        navigation={true}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        // pagination={true}
        className="mySwiper"
        autoplay={{ delay: 1500 }}
        style={{ padding: "50px 0" }}
      >
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/3wtk4tDy/homeadict.jpg" />
        </SwiperSlide>
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/ZRg0j0H4/trudimensions.png" />
        </SwiperSlide>
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/v8k6t22f/cloudclass.jpg" />
        </SwiperSlide>
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/8CNvrFKY/Binary-Brain.jpg" />
        </SwiperSlide>
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/1t4fpDpv/playberries.jpg" />
        </SwiperSlide>
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/ZndnQZs3/ward6.png" />
        </SwiperSlide>
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/KYGK864S/logo1.png" />
        </SwiperSlide>
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/CxHBYhhs/GiveBack.jpg" />
        </SwiperSlide>

        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/brDSj1rZ/c1.png" />
        </SwiperSlide>
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/4dFHMWfC/c2.jpg" />
        </SwiperSlide>
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/8zVJqJSC/c3.jpg" />
        </SwiperSlide>
        <SwiperSlide className="ClientLogoSlider-SwiperSlider">
          <img src="https://i.postimg.cc/yxMg6W3v/c4.jpg" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
