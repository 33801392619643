import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listProducts } from "../../actions/blogActions";
import LoadingBox from "../LoadingBox";
import MessageBox from "../MessageBox";

export default function BlogCardsForHomeScreen() {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);
  return (
    <div className="blogpost-Body" data-aos="fade-in">
      <div className="blog-posts">
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            {products.length > 0 ? (
              products.slice(0, 3).map((m) => (
                <Link
                  to={`/blog/${m._id}`}
                  className="post"
                  key={m._id}
                  data-aos="fade-up"
                >
                  {m.image === "tech1.webp" ? (
                    <img
                      src={`images/${m.image}`}
                      alt=""
                      className="post-img"
                    />
                  ) : (
                    <img src={m.image} alt="" className="post-img" />
                  )}

                  <div className="post-content">
                    <h3>{m.name}</h3>
                    {/* <span className="date">
                      <i className="far fa-calendar-alt"></i>
                      &nbsp;&nbsp;&nbsp;July 21, 2021
                    </span> */}
                  </div>
                </Link>
              ))
            ) : (
              <h5 style={{ color: "red" }}>No Data Found</h5>
            )}
          </>
        )}

        <div className="showmore" style={{ textAlign: "center" }}>
          <a href="/blogs">
            <h6>show more</h6>
            <h3>ᗐ</h3>
          </a>
        </div>
      </div>
    </div>
  );
}
