import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Test from "./Test";
import CompanyDetails from "./CompanyDetails";

export default function AboutScreen() {
  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN backgroundcolor-f2f2f2">
        <div
          className="aboutInfoContainer BlankBoxContainer"
          data-aos="fade-in"
        >
          <div className="aboutInfoContainer-left zindex-100 rotate-neg3">
            {/* <img src="/images/teamgroup1.jpeg" data-aos="fade-up"></img> */}
            <img
              src="https://i.postimg.cc/KY12ffH3/teamgroup1.jpg"
              data-aos="fade-up"
            ></img>
          </div>
          <div className="aboutInfoContainer-right zindex-100">
            <p data-aos="fade-up">
              At Amabze, we develop innovative and creative products and
              services that provide total communication and information
              solutions. Among a plethora of services, IOS Application, Android
              software development, web design and development, tailor made
              applications, e-commerce solutions, business-to-business
              applications, business-to-client applications, managed hosting and
              internet portal management are few that we offer. Satisfied
              clients around the globe bear testimony to the quality of our
              work. As a leader in technology exploring, Amabze is committed to
              exporting quality software worldwide. The general purpose of
              Amabze is to develop and promote advanced information technologies
              for multi-user operation."
            </p>
          </div>
          <div className="BlankBox"> </div>
        </div>
        <Test />
        <div style={{ textAlign: "center", padding: "20px 0" }}>
          <span className="text3D">Our Clients</span>
        </div>
        <div className="About-ClientsLogo CenterBody" data-aos="fade-up">
          {/* <img src="/images/clientlogo/homeadict.jpg" />
          <img src="/images/clientlogo/trudimensions.png" />
          <img src="/images/clientlogo/cloudclass.png" />
          <img src="/images/clientlogo/BinaryBrain.png" />
          <img src="/images/clientlogo/playberries.jpg" />
          <img src="/images/clientlogo/ward6.png" />
          <img src="/images/clientlogo/logo1.png" />
          <img src="/images/clientlogo/GiveBack.jpeg" />
          <img src="/images/clientlogo/c1.jpeg" />
          <img src="/images/clientlogo/c2.jpeg" />
          <img src="/images/clientlogo/c3.jpeg" />
          <img src="/images/clientlogo/c4.jpeg" /> */}

          <img src="https://i.postimg.cc/3wtk4tDy/homeadict.jpg" />
          <img src="https://i.postimg.cc/ZRg0j0H4/trudimensions.png" />
          <img src="https://i.postimg.cc/v8k6t22f/cloudclass.jpg" />
          <img src="https://i.postimg.cc/8CNvrFKY/Binary-Brain.jpg" />
          <img src="https://i.postimg.cc/1t4fpDpv/playberries.jpg" />
          <img src="https://i.postimg.cc/ZndnQZs3/ward6.png" />
          <img src="https://i.postimg.cc/KYGK864S/logo1.png" />
          <img src="https://i.postimg.cc/CxHBYhhs/GiveBack.jpg" />
          <img src="https://i.postimg.cc/brDSj1rZ/c1.png" />
          <img src="https://i.postimg.cc/4dFHMWfC/c2.jpg" />
          <img src="https://i.postimg.cc/8zVJqJSC/c3.jpg" />
          <img src="https://i.postimg.cc/yxMg6W3v/c4.jpg" />
        </div>
        <CompanyDetails />
      </div>
      <Footer />
    </div>
  );
}
