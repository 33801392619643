import React from "react";
import "./demo.css";

export default function Demo() {
  return (
    <>
      <div style={{ textAlign: "center", padding: "50px 0" }}>
        <span className="text3D">Our STRATEGY</span>
      </div>
      <p
        style={{
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "600",
          padding: "10px",
        }}
      >
        Our team uses a straightforward, efficient and effective methodology for
        development execution that we call
        <span style={{ color: "green" }}>SMART</span>
      </p>

      <div
        className="aboutInfoContainer overflow-hidden"
        data-aos="fade-in"
        style={{ margin: "20px 0" }}
      >
        <div className="aboutTech-img">
          <img src="/images/learn.png" className="animation-move"></img>
        </div>
        <div className="aboutInfoContainer-right">
          <ol className="aboutTech-ol">
            <li className="aboutTech-li" data-aos="fade-left"    data-aos-duration="2000">
              <span className="aboutTech-span">01</span>
              <p style={{ margin: "0", color: "green", fontWeight: "700" }}>
                SPECIFIC
              </p>
              <p style={{ margin: "0" }}>
                We sit down with you to define your objectives precisely and lay
                out a detailed plan to accomplish them.
              </p>
            </li>
            <li className="aboutTech-li" data-aos="fade-left"  data-aos-duration="2000">
              <span className="aboutTech-span">02</span>
              <p style={{ margin: "0", color: "green", fontWeight: "700" }}>
                MEASURABLE
              </p>
              <p style={{ margin: "0" }}>
                To keep your project progress on track, we segment the job into
                manageable chunks and establish milestones and measurements for
                each stage.
              </p>
            </li>
            <li className="aboutTech-li" data-aos="fade-left"  data-aos-duration="2000">
              <span className="aboutTech-span">03</span>
              <p style={{ margin: "0", color: "green", fontWeight: "700" }}>
                AGREED UPON
              </p>
              <p style={{ margin: "0" }}>
                Once you have signed off on the plan, you can be assured that we
                will come through for you.
              </p>
            </li>
            <li className="aboutTech-li" data-aos="fade-left"  data-aos-duration="2000">
              <span className="aboutTech-span">04</span>
              <p style={{ margin: "0", color: "green", fontWeight: "700" }}>
                REALISTIC
              </p>
              <p style={{ margin: "0" }}>
                We ensure that our plans are thorough and that we apply the
                appropriate resources to get the job done right.
              </p>
            </li>
            <li className="aboutTech-li" data-aos="fade-left"  data-aos-duration="2000">
              <span className="aboutTech-span">05</span>
              <p style={{ margin: "0", color: "green", fontWeight: "700" }}>
                TIME FRAMED
              </p>
              <p style={{ margin: "0" }}>
                Your project stays on track because we have the strongest
                professional commitment to honoring agreed-upon timelines.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}
