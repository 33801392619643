import logo from "./logo.svg";
import "./App.css";
import "./screen.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Switch } from "react-router-dom";
import AdminRoute from "./Components/AdminRoute";
import PrivateRoute from "./Components/PrivateRoute";
import HomeScreen from "./Components/Screens/HomeScreen";
import AboutScreen from "./Components/Screens/AboutScreen";
import Demo from "./Components/Screens/Demo";
import CompanyDetails from "./Components/Screens/CompanyDetails";
import Products from "./Components/Screens/Products";
import TimelineCompany from "./Components/Screens/TimelineCompany";
import WhyAmabze from "./Components/Screens/WhyAmabze";
import WhatWeDo from "./Components/Screens/WhatWeDo";
import InternshipScreen from "./Components/Screens/InternshipScreen";
import Gallery from "./Components/Screens/Gallery";
import InternshipContactUsScreen from "./Components/Screens/InternshipContactUsScreen";
import SigninScreen from "./Components/Screens/SigninScreen";
import InternshipUserContactusScreen from "./Components/AdminScreens/InternshipUserContactusScreen";
import BlogsScreen from "./Components/Screens/BlogsScreen";
import BlogsListScreen from "./Components/AdminScreens/BlogsListScreen";
import BlogsListEditScreen from "./Components/AdminScreens/BlogsListEditScreen";
import BlogScreen from "./Components/Screens/BlogScreen";
import ThankYouScreen from "./Components/Screens/ThankYouScreen";
import ContactMeScreen from "./Components/Screens/ContactMeScreen";
import UserContactMeScreen from "./Components/AdminScreens/UserContactMeScreen";
import CurrentOpning from "./Components/Screens/CurrentOpning";
import CurrentOpningApplyScreen from "./Components/Screens/CurrentOpningApplyScreen";
import JobsListScreen from "./Components/AdminScreens/JobsListScreen";
import JobsListEditScreen from "./Components/AdminScreens/JobsListEditScreen";
import ClientLogoSlider from "./Components/Screens/ClientLogoSlider";
import JobsApplyListScreen from "./Components/AdminScreens/JobsApplyListScreen";
import InnovationContestScreen from "./Components/Screens/InnovationContestScreen";
import InnovationContestAdminScreen from "./Components/AdminScreens/InnovationContestAdminScreen";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={HomeScreen}></Route>
        <Route path="/thankyou" component={ThankYouScreen}></Route>
        <Route
          path="/innovationcontest"
          component={InnovationContestScreen}
        ></Route>
        <Route path="/demo" component={Demo}></Route>
        <Route path="/about" component={AboutScreen}></Route>
        <Route path="/companydetails" component={CompanyDetails}></Route>
        <Route path="/service-product" component={Products}></Route>
        <Route path="/whyamabze" component={WhyAmabze}></Route>
        <Route path="/whatwedo" component={WhatWeDo}></Route>
        <Route path="/internship" component={InternshipScreen}></Route>
        <Route path="/currentopning" component={CurrentOpning}></Route>
        <Route
          path="/currentopningapply"
          component={CurrentOpningApplyScreen}
        ></Route>

        <Route path="/gallery" component={Gallery}></Route>
        <Route path="/contactus" component={InternshipContactUsScreen}></Route>
        <Route path="/contactme" component={ContactMeScreen}></Route>
        <Route path="/signin" component={SigninScreen}></Route>
        <Route path="/blogs" component={BlogsScreen}></Route>
        <Route path="/blog/:id" component={BlogScreen} exact></Route>
        <AdminRoute
          path="/blog/:id/edit"
          component={BlogsListEditScreen}
          exact
        ></AdminRoute>
        <AdminRoute path="/bloglist" component={BlogsListScreen}></AdminRoute>

        <AdminRoute
          path="/internship-user-list"
          component={InternshipUserContactusScreen}
        ></AdminRoute>
        <AdminRoute
          path="/jobapplylist"
          component={JobsApplyListScreen}
        ></AdminRoute>

        <AdminRoute
          path="/contactmelist"
          component={UserContactMeScreen}
        ></AdminRoute>

        <AdminRoute
          path="/innovationcontestlist"
          component={InnovationContestAdminScreen}
        ></AdminRoute>

        <Route
          path="/job/:id"
          component={CurrentOpningApplyScreen}
          exact
        ></Route>
        <AdminRoute path="/joblist" component={JobsListScreen}></AdminRoute>
        <AdminRoute
          path="/job/:id/edit"
          component={JobsListEditScreen}
          exact
        ></AdminRoute>
      </Switch>
    </div>
  );
}

export default App;
