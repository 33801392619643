import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Header";
import Footer from "../Footer";
import MessageBox from "../MessageBox";
import LoadingBox from "../LoadingBox";
import Axios from "axios";
import { JOB_UPDATE_RESET } from "../../constants/jobConstants";
import { detailsJob, updateJob } from "../../actions/jobActions";

export default function JobsListEditScreen(props) {
  const productId = props.match.params.id;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const jobDetails = useSelector((state) => state.jobDetails);
  const { loading, error, product } = jobDetails;

  const jobUpdate = useSelector((state) => state.jobUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = jobUpdate;

  const dispatch = useDispatch();
  useEffect(() => {
    if (successUpdate) {
      props.history.push("/joblist");
    }
    if (!product || product._id !== productId || successUpdate) {
      dispatch({ type: JOB_UPDATE_RESET });
      dispatch(detailsJob(productId));
    } else {
      setName(product.name);
      setDescription(product.description);
    }
  }, [product, dispatch, productId, successUpdate, props.history]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateJob({
        _id: productId,
        name,
        description,
      })
    );
  };

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN backgroundcolor-f2f2f2">
        <form className="UserAccountform" onSubmit={submitHandler}>
          <h5>Edit Product {productId}</h5>

          {loadingUpdate && <LoadingBox></LoadingBox>}
          {errorUpdate && (
            <MessageBox variant="danger">{errorUpdate}</MessageBox>
          )}
          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <>
              <div>
                <label htmlFor="name">Name</label>
                <input
                  className="UserAccountform-input"
                  id="name"
                  type="text"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>

              <div>
                <label htmlFor="description">Description</label>
                <textarea
                  className="UserAccountform-input"
                  id="description"
                  rows="3"
                  type="text"
                  placeholder="Enter description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div>
                <label></label>
                <button className="UserAccountform-btn" type="submit">
                  Update
                </button>
              </div>
            </>
          )}
        </form>
      </div>
      <Footer />
    </div>
  );
}
