import React from "react";
import ImageDataLink from "./ImageDataLink";

export default function Test() {
  return (
    <section className="aboutTeam-team">
      <div className="aboutTeam-container">
        <div className="aboutTeam-row">
          <div className="aboutTeam-teamItem">
            {/* <img src="/images/abjsir.png" alt="team" className="aboutTeam-img" /> */}
            <img
              src="https://i.postimg.cc/R056x3xF/abjsir.jpg"
              alt="team"
              className="aboutTeam-img"
            />
            <h3>
              Abhijeet Kumar <span>Director</span>
            </h3>
            <div className="social-links">
              <a href="https://www.facebook.com/abhijeet.kumar.94064176">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.instagram.com/abhijeet_kumar_92/">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/in/abhijeet-kumar-97328a112/">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>

          <div className="aboutTeam-teamItem">
            {/* <img src="/images/abjsir.png" alt="team" className="aboutTeam-img" /> */}
            <img
              src="https://i.postimg.cc/QxGgSC5Z/BBF.jpg"
              alt="team"
              className="aboutTeam-img"
            />
            <h3>
              Rupesh Yadav <span>Director</span>
            </h3>
            <div className="social-links">
              <a href="https://www.facebook.com/abhijeet.kumar.94064176">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.instagram.com/abhijeet_kumar_92/">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/in/abhijeet-kumar-97328a112/">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="aboutTeam-row">
          <div class="section-title">
            <h2>
              Our <span>creative core</span> team
            </h2>
          </div>
        </div>

        <div className="aboutTeam-row">
          {ImageDataLink.CoreTeam.map((per) => (
            <div className="aboutTeam-teamItem" key={per._id}>
              <img src={per.image} alt="team" className="aboutTeam-img" />
              <h3>
                {per.name} <span>{per.work}</span>
              </h3>
              <div className="social-links">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
