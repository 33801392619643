import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BLOG_CREATE_RESET, BLOG_DELETE_RESET } from "../../constants/blogConstants";
import Header from "../Header";
import Footer from "../Footer";
import { createProduct, deleteProduct, listProducts } from "../../actions/blogActions";
import LoadingBox from "../LoadingBox";
import MessageBox from "../MessageBox";

export default function BlogsListScreen(props) {
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const dispatch = useDispatch();
  useEffect(() => {
    if (successCreate) {
      dispatch({ type: BLOG_CREATE_RESET });
      props.history.push(`/blog/${createdProduct._id}/edit`);
    }
    if (successDelete) {
      dispatch({ type: BLOG_DELETE_RESET });
    }
    dispatch(listProducts());
  }, [createdProduct, dispatch, props.history, successCreate, successDelete]);

  const deleteHandler = (product) => {
    if (window.confirm("Are you sure to delete?")) {
      dispatch(deleteProduct(product._id));
      window.location.reload();
    }
  };
  const createHandler = () => {
    dispatch(createProduct());
  };

  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN backgroundcolor-f2f2f2" style={{textAlign:"center"}}>
      <h1 style={{padding:"20px 5px", fontWeight:"600"}}>Blogs List</h1>

        <div className="MenuBtnContainer">
          <button
            type="button"
            className="MenuBtn"
            style={{
              fontSize: "10px",
              fontWeight: "600",
              padding: "5px",
            }}
            onClick={createHandler}
          >
            Create Blog
          </button>
        </div>

        {loadingDelete && <LoadingBox></LoadingBox>}
        {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
        {loadingCreate && <LoadingBox></LoadingBox>}
        {errorCreate && <MessageBox variant="danger">{errorCreate}</MessageBox>}
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div className="scrollTableH">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>TITLE</th>
                <th>DESCRIPTION</th>
                <th>FILE</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product._id}>
                  <td>{product._id}</td>
                  <td>{product.name}</td>
                  <td>{product.description}</td>
                  <td>{product.image}</td>
                  <td>
                    <div className="MenuBtnContainer">
                      <button
                        type="button"
                        className="MenuBtn"
                        style={{
                          fontSize: "10px",
                          fontWeight: "600",
                          padding: "5px",
                        }}
                        onClick={() =>
                          props.history.push(`/blog/${product._id}/edit`)
                        }
                      >
                        Edit
                      </button>
                    </div>

                    <div className="MenuBtnContainer">
                      <button
                        type="button"
                        className="MenuBtn"
                        style={{
                          fontSize: "10px",
                          fontWeight: "600",
                          padding: "5px",
                        }}
                        onClick={() => deleteHandler(product)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
