import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ApplyForm from "../SingleComponents/ApplyForm";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { detailsJob } from "../../actions/jobActions";
import LoadingBox from "../LoadingBox";
import MessageBox from "../MessageBox";

export default function CurrentOpningApplyScreen(props) {
  const dispatch = useDispatch();
  const productId = props.match.params.id;
  const jobDetails = useSelector((state) => state.jobDetails);
  const { loading, error, product } = jobDetails;

  useEffect(() => {
    dispatch(detailsJob(productId));
  }, [dispatch, productId]);
  return (
    <div className="SCREEN-CONTAINER overflow-hidden backgroundcolor-f2f2f2">
      <Header />
      <div className="MAIN  backgroundcolor-f2f2f2 CenterBody">
        <Row>
          <Col xs={12} md={6} className="TwoPartScreen-col">
            <div style={{ padding: "10px" }}>
              {loading ? (
                <LoadingBox></LoadingBox>
              ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>
              ) : (
                <div>
                  <h1 style={{fontWeight:"700"}}>{product.name}</h1>

                  {product.description ? <p className="hyphens-auto" >{product.description}</p> : null}
                </div>
              )}
            </div>
          </Col>
          <Col xs={12} md={6} className="TwoPartScreen-col cardContainer ">
            {
              product ?  <ApplyForm jobTitle={product.name} jobID={product._id} /> : null
            }
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}
