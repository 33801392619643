export const USER_CONTACTUS_REQUEST = "USER_CONTACTUS_REQUEST";
export const USER_CONTACTUS_SUCCESS = "USER_CONTACTUS_SUCCESS";
export const USER_CONTACTUS_FAIL = "USER_CONTACTUS_FAIL";

export const USERLIST_CONTACTUS_REQUEST = "USERLIST_CONTACTUS_REQUEST";
export const USERLIST_CONTACTUS_SUCCESS = "USERLIST_CONTACTUS_SUCCESS";
export const USERLIST_CONTACTUS_FAIL = "USERLIST_CONTACTUS_FAIL";

export const USERDELETE_CONTACTUS_REQUEST = "USERDELETE_CONTACTUS_REQUEST";
export const USERDELETE_CONTACTUS_SUCCESS = "USERDELETE_CONTACTUS_SUCCESS";
export const USERDELETE_CONTACTUS_FAIL = "USERDELETE_CONTACTUS_FAIL";
export const USERDELETE_CONTACTUS_RESET = "USERDELETE_CONTACTUS_RESET";