export const JOBAPPLYLIST_CONTACTUS_REQUEST = "JOBAPPLYLIST_CONTACTUS_REQUEST";
export const JOBAPPLYLIST_CONTACTUS_SUCCESS = "JOBAPPLYLIST_CONTACTUS_SUCCESS";
export const JOBAPPLYLIST_CONTACTUS_FAIL = "JOBAPPLYLIST_CONTACTUS_FAIL";

export const JOBAPPLYDELETE_CONTACTUS_REQUEST =
  "JOBAPPLYDELETE_CONTACTUS_REQUEST";
export const JOBAPPLYDELETE_CONTACTUS_SUCCESS =
  "JOBAPPLYDELETE_CONTACTUS_SUCCESS";
export const JOBAPPLYDELETE_CONTACTUS_FAIL = "JOBAPPLYDELETE_CONTACTUS_FAIL";
export const JOBAPPLYDELETE_CONTACTUS_RESET = "JOBAPPLYDELETE_CONTACTUS_RESET";
