import React from "react";

export default function ClientReview(props) {
  return (
    <div
      className="clientReviewBox"
      style={{ textAlign: "center" }}
    >
      <p className="hyphens-auto"> {props.review}</p>

      <div className="clientReviewBox-client">
        <img
          src={props.image}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />

        <h5>
          {props.clientName}
          <br />
          <i className="fas fa-star" style={{ fontSize: "10px" }}></i>&nbsp;
          <i className="fas fa-star" style={{ fontSize: "10px" }}></i>&nbsp;
          <i className="fas fa-star" style={{ fontSize: "10px" }}></i>&nbsp;
          <i className="fas fa-star" style={{ fontSize: "10px" }}></i>&nbsp;
          <i className="fas fa-star" style={{ fontSize: "10px" }}></i>&nbsp;
        </h5>
      </div>
    </div>
  );
}
