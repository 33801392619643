import React from "react";

export default function Footer() {
  // return <div style={{ backgroundColor: "black", color: "white" }}>footer</div>;
  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        <h3>
          <span>Amabze</span>
        </h3>

        <p className="footer-links">
          <a href="/">Home&nbsp;</a>|<a href="/about">&nbsp;About&nbsp;</a>|
          <a href="/contactme">&nbsp;Contact&nbsp;</a>|
          <a href="/blogs">&nbsp;Blog&nbsp;</a>
        </p>

        <p className="footer-company-name">
          Copyright © 2021 <strong>amabze</strong> All rights reserved
        </p>
      </div>

      <div className="footer-center">
        <div className="FooterBox">
          <i className="fa fa-map-marker"></i>
          <div className="FooterBoxInfo">
            <p>
              #803, 16th Main, 13th Cross, BTM 2nd Stage Bangalore, Karnataka
              560076 India
            </p>
          </div>
        </div>

        <div className="FooterBox">
          <i className="fa fa-phone"></i>
          <div className="FooterBoxInfo">
            <p>+91 8088340845</p>
            <br />
            <p>+91 7758802331</p>
          </div>
        </div>

        <div className="FooterBox">
          <i className="fa fa-envelope"></i>
          <p className="FooterBoxInfo">
            <a href="mailto:amabzeindia@gmail.com">amabzeindia@gmail.com</a>
          </p>
        </div>
      </div>
      <div className="footer-right">
        <p className="footer-company-about">
          <span>About the company</span>
          At Amabze, we develop innovative and creative products and services
          that provide total communication and information solutions.
        </p>
        <div className="footer-icons">
          <a href="#">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/in/amabze-technolozy-pvt-ltd-162b14186/">
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a href="#">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCVCTIip2EKJJVTz8Zq9T61w">
            <i className="fab fa-youtube"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}
