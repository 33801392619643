import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export default function Products() {
  return (
    <div className="SCREEN-CONTAINER overflow-hidden">
      <Header />
      <div className="MAIN  backgroundcolor-f2f2f2 ">
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="
            Our team has a decade-long experience in custom mobile application development that spans a wide range of industries from retail to healthcare and energy.
            You want a team of mobile app developers who can dive into your business ecosystem. Who can explore the market and understand your needs, requirements and capabilities. Who can build a truly custom strategy and unlock the full potential of mobile technology for your company.
            This is what custom mobile app development is about. We get to know your business and tailor our application development services to your goals and expectations.
            "
            iconStyle={{
              background: "white",
              textAlign: "center",
              fontSize: "30px",
            }}
            icon={
              <img
                src="/images/services/app-development.png"
                style={{ width: "40px" }}
               
              />
            }
          >
            <h3
              className="vertical-timeline-element-title"
              style={{ fontWeight: "600" }}
            >
              Apps Development
            </h3>
            <div style={{borderBottom:"5px solid  rgba(255, 255, 255, .5)", padding:"10px 0"}}></div>
            <p style={{ fontSize: "1rem", fontWeight:600 }}>
              We develop for range of platforms (iOS, Android & Windows) but
              build the app native for each of them to ensure the best user
              experience on each platform with our team of passionate
              developers.
            </p>
            <img
              src="images/mobiledevelopment_f2f2f2.svg"
              alt=""
              className="servicesTech-img"
            />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="We offer custom website development services for small, mid-sized and largest business houses as per their business needs. We have many years of experience in delivering the best web development services 100% accuracy and perfectly matching to your business demands. Our dedicated team comprises of expert web developers, who are very proficient in using cutting-edge technologies to customize everything professionally.
            We offer also E-commerce development services. Our developers have rich experience and depth knowledge in E-commerce development.  As a result, we are offering the best in all e-commerce portals whether it is a B2C or B2B portals. Our developers having experience in implementation of all kinds of shopping cart software and payment gateways available in the market."
            iconStyle={{
              background: "white",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            icon={
              <img
                src="/images/services/web-coding.png"
                style={{ width: "40px" }}
              />
            }
          >
            <h3
              className="vertical-timeline-element-title"
              style={{ fontWeight: "600" }}
            >
              Web Development
            </h3>
            <div style={{borderBottom:"5px solid  rgba(255, 255, 255, .5)", padding:"10px 0"}}></div>
            <p style={{ fontSize: "1rem", fontWeight:600 }}>
              Web design is both an art and a science. Working with you to
              understand your target audience, we can create a web design that
              will accomplish both goals.
            </p>
            <img
              src="images/webdevelopment_f2f2f2.svg"
              alt=""
              className="servicesTech-img"
            />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="Our AI developers build custom machine learning software to create actionable decision models and automate business processes. We transform raw data from big data providers and legacy software systems into clean datasets to execute classification, clustering and regression and deploy models across the systems.
            Leverage our expertise in Deep Learning to build cognitive business technology frameworks that act like a human being. By digging deeper into complex data, we unleash enormous opportunities for your business and deliver precise solutions to save costs."
            iconStyle={{
              background: "white",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            icon={
              <img
                src="/images/services/machine-learning.png"
                style={{ width: "40px" }}
              />
            }
          >
            <h3
              className="vertical-timeline-element-title"
              style={{ fontWeight: "600" }}
            >
              Machine Learning
            </h3>
            <div style={{borderBottom:"5px solid  rgba(255, 255, 255, .5)", padding:"10px 0"}}></div>
            <p style={{ fontSize: "1rem", fontWeight:600 }}>
              We develop applications that can turn any device into a powerful
              content augmentation platform, using basic and affordable
              hardware.
            </p>
            <img src="images/ml.svg" alt="" className="servicesTech-img" />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="With the help of AI, we can develop smart systems that will not only help us in businesses or offices but also at home. Smart systems can perform so many tasks for us, right from setting the alarm to switching on/off the lights.
            With the help of AI, gathering or collecting data from different portals becomes much easier. With the help of ML, we can apply different algorithms to data to get it in our required form.
            While doing online shopping, we get recommendations based on what we see or purchase. This, in turn, will help in getting more business. All this is possible, just because of AI (Deep Learning and Machine Learning).
            When you want to buy some products or services, you probably visit the concerned website, where you get help through online conversation or chatting window that is always available. This 24*7 help is possible only because of AI (Chatbot)."
            iconStyle={{
              background: "white",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            icon={
              <img
                src="/images/services/artificial-intelligence.png"
                style={{ width: "40px" }}
              />
            }
          >
            <h3
              className="vertical-timeline-element-title"
              style={{ fontWeight: "600" }}
            >
              Artificial Intelligence
            </h3>
            <div style={{borderBottom:"5px solid  rgba(255, 255, 255, .5)", padding:"10px 0"}}></div>
            <p style={{ fontSize: "1rem", fontWeight:600 }}>
              We provide a state-of-art online management and administration
              system using ML and AI. We are one of the few organizations using
              AI. We are implementing Deep Learning tools.
            </p>
            <img
              src="images/artificialintelligence.svg"
              alt=""
              className="servicesTech-img"
            />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="Smart Enterprises are unleashing Robotic Process Automation tools and Artificial Intelligence solutions to not only make their workforce relevant for business processes but also overcome operational and technical vulnerabilities endangering their existence in the midst of growing competition. Equipped with the latest Cognitive Robotic processes, Enterprises are able to reduce human errors bound to happen in manual work processes, automate rules-based processes with the combination of artificial intelligence (AI), machine learning (ML), big data, speech recognition technologies and deep neural networks. Additionally, Intelligent Process Automation helps reduce work, create new types of work in the form of RPA programming, enables new workflows, process automation, and makes you capable of handling critical organizational tasks that are time consuming.
             "
            iconStyle={{
              background: "white",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            icon={
              <img src="/images/services/robot.png" style={{ width: "40px" }} />
            }
          >
            <h3
              className="vertical-timeline-element-title"
              style={{ fontWeight: "600" }}
            >
              Robotic Process Automation
            </h3>
            <div style={{borderBottom:"5px solid  rgba(255, 255, 255, .5)", padding:"10px 0"}}></div>
            <p style={{ fontSize: "1rem", fontWeight:600 }}>
              We develop applications that can turn any device into a powerful
              content augmentation platform, using basic and affordable
              hardware.
            </p>
            <img
              src="images/robotics.svg"
              alt=""
              className="servicesTech-img"
            />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="Our management consulting services focus on our clients' most critical issues and opportunities: strategy, marketing, organization, operations, technology, transformation, digital, advanced analytics, corporate finance, mergers & acquisitions and sustainability across all industries and geographies. We bring deep, functional expertise, but are known for our holistic perspective: we capture value across boundaries and between the silos of any organization. We have proven a multiplier effect from optimizing the sum of the parts, not just the individual pieces."
            iconStyle={{
              background: "white",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            icon={
              <img
                src="/images/services/consulting.png"
                style={{ width: "40px" }}
              />
            }
          >
            <h3
              className="vertical-timeline-element-title"
              style={{ fontWeight: "600" }}
            >
              Consulting
            </h3>
            <div style={{borderBottom:"5px solid  rgba(255, 255, 255, .5)", padding:"10px 0"}}></div>
            <p style={{ fontSize: "1rem", fontWeight:600 }}>
              We develop applications that can turn any device into a powerful
              content augmentation platform, using basic and affordable
              hardware.
            </p>
            <img
              src="images/Consulting.svg"
              alt=""
              className="servicesTech-img"
            />
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{
              background: "rgb(33, 150, 243)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="Online marketing, also known as digital marketing, is the key business tool for enterprises today as it enables them to harness the power of the internet to take their business to the next level. It helps them gain visibility across the search engines, boost the website traffic, and engage users so that they get converted into customers. 
            We have a team of expert digital marketers to help you with A to Z of online marketing. We offer a complete range of digital marketing services to ensure that your business website reaches the top and is able to stay there despite the competition in the market. From SEO to SMO, SMM, PPC, ORM, content marketing and more, we excel at every aspect of online marketing and create a perfect mix of these strategies to bring a result-oriented digital plan for your business. "
            iconStyle={{
              background: "white",
              color: "#fff",
              textAlign: "center",
              fontSize: "30px",
            }}
            icon={
              <img
                src="/images/services/DigitalMarketing.png"
                style={{ width: "40px" }}
              />
            }
          >
            <h3
              className="vertical-timeline-element-title"
              style={{ fontWeight: "600" }}
            >
              Digital Marketing
            </h3>
            <div style={{borderBottom:"5px solid  rgba(255, 255, 255, .5)", padding:"10px 0"}}></div>
            <p style={{ fontSize: "1rem", fontWeight:600 }}>
              We strive to not only harness the power of SEO, AdWords & Facebook
              Marketing to place your business in front of the right audience
              but to strategize your marketing plan keeping in mind your brand
              perception and conversions.
            </p>
            <img
              src="images/DigitalMarketing.svg"
              alt=""
              className="servicesTech-img"
            />
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
      <Footer />
    </div>
  );
}
