import React, { useEffect } from "react";
import "./Header.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../actions/userActions";

export default function Header() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      //   bg="dark"
      variant="dark"
      style={{ padding: "0", backgroundColor: "black", zIndex: "200" }}
      className="sd-5 nav-fixed"
    >
      <Navbar.Brand
        href="/"
        style={{ marginLeft: "2rem", marginRight: "8rem", padding: "0" }}
      >
        <img
          src="https://i.postimg.cc/RVqWYWZk/amabzelogo.jpg"
          className="logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/service-product">
            Service & Product
          </Nav.Link>
          <Nav.Link as={Link} to="/blogs">
            Blogs
          </Nav.Link>
          <Nav.Link as={Link} to="/about">
            About Us
          </Nav.Link>

          <NavDropdown title="Careers" id="collasible-nav-dropdown">
            <NavDropdown.Item as={Link} to="/whyamabze">
              why Amabze
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/whatwedo">
              what we do
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/internship">
              Internship
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/currentopning">
              Current Opening
            </NavDropdown.Item>
            {/* <NavDropdown.Divider /> */}
          </NavDropdown>

          <Nav.Link href="/gallery">Gallery</Nav.Link>
          <Nav.Link as={Link} to="/contactme">
            Contact Us
          </Nav.Link>
          <Nav.Link as={Link} to="/innovationcontest" style={{ color: "#fff" }}>
            Innovation Contest
          </Nav.Link>

          {userInfo ? (
            <NavDropdown
              title={userInfo.name}
              id="collasible-nav-dropdown"
              className="navItem"
            >
              <NavDropdown.Item
                as={Link}
                to="#signout"
                onClick={signoutHandler}
              >
                SignOut
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Nav.Link as={Link} to="/signin" className="navItem">
              SignIn
            </Nav.Link>
          )}

          {userInfo && userInfo.isAdmin && (
            <NavDropdown
              title="Admin"
              id="collasible-nav-dropdown"
              className="navItem"
            >
              <NavDropdown.Item as={Link} to="/internship-user-list">
                Internship User List
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/bloglist">
                Blogs List
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/contactmelist">
                contact Me List
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/joblist">
                Job List
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/jobapplylist">
                Job Apply List
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/innovationcontestlist">
                Innovation Contest List
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
        {/* <Nav   style={{ marginLeft: "2rem", marginRight: "8rem", padding: "0" }}>
          <Nav.Link href="#deets">Sign In</Nav.Link>
          <Nav.Link eventKey={2} href="#memes">
            Sign Up
          </Nav.Link>
        </Nav> */}
      </Navbar.Collapse>
    </Navbar>
  );
}
