import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../LoadingBox";
import MessageBox from "../MessageBox";
import Header from "../Header";
import Footer from "../Footer";
import { signin } from "../../actions/userActions";

export default function SigninScreen(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, password));
  };

  useEffect(() => {
    if (userInfo) {
      props.history.push("/");
    }
  }, [props.history, userInfo]);

  return (
    <div className="SCREEN-CONTAINER">
    <Header />
    <div className="MAIN backgroundcolor-f2f2f2">
      <form className="UserAccountform" onSubmit={submitHandler}>
        <div>
          <h1>Sign In</h1>
        </div>
        {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger">{error}</MessageBox>}
        <div>
          <label htmlFor="email" style={{ textAlign: "initial" }}>
            Email
          </label>
          <input
            className="UserAccountform-input"
            type="email"
            id="email"
            placeholder="Enter email"
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="password" style={{ textAlign: "initial" }}>
            Password
          </label>
          <input
            className="UserAccountform-input"
            type="password"
            id="password"
            placeholder="Enter password"
            required
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div>
          <label />
          <button className="UserAccountform-btn" type="submit">
            Sign In
          </button>
        </div>
        <div>
          <label />
          {/* <div>
            New customer? <Link to="/register">Create your account</Link>
          </div> */}
        </div>
      </form>
    </div>
    <Footer />
    </div>
  );
}
