const data = {
  gallery: [
    {
      _id: "1",
      image: "/images/amabzeG1.jpeg",
    },
    {
      _id: "2",
      image: "/images/amabzeG2.jpeg",
    },
    {
      _id: "3",
      image: "/images/amabzeG3.jpeg",
    },
    {
      _id: "4",
      image: "/images/amabzeG4.jpeg",
    },

    {
      _id: "5",
      image: "/images/amabzeG5.jpeg",
    },
    {
      _id: "6",
      image: "/images/amabzeG6.jpeg",
    },
    {
      _id: "7",
      image: "/images/amabzeG7.jpeg",
    },
    {
      _id: "8",
      image: "/images/amabzeG8.jpeg",
    },
    {
      _id: "9",
      image: "/images/amabzeG9.jpeg",
    },
  ],
  internshipTable: [
    {
      _id: "1",
      Language: "HTML, CSS, JAVASCRIPT",
      TimeDuration: "45 - 60 days",
      CourseCode: "AMABZE01",
    },
    {
      _id: "2",
      Language: "HTML, CSS, JAVASCRIPT, ANGULAR",
      TimeDuration: "60 - 90 days",
      CourseCode: "AMABZE02",
    },
    {
      _id: "3",
      Language: "HTML, CSS, JAVASCRIPT, REACT.JS",
      TimeDuration: "60 - 90 days",
      CourseCode: "AMABZE03",
    },
    {
      _id: "4",
      Language: "HTML, CSS, JAVASCRIPT, NODE.JS, MONGO DB",
      TimeDuration: "90 - 120 days",
      CourseCode: "AMABZE04",
    },
    {
      _id: "5",
      Language: "HTML, CSS, JAVASCRIPT, PHP",
      TimeDuration: "90 - 120 days",
      CourseCode: "AMABZE05",
    },
    {
      _id: "6",
      Language: "IOS (SWIFT)",
      TimeDuration: "90 - 120 days",
      CourseCode: "AMABZE06",
    },
    {
      _id: "7",
      Language: "ANDROID (JAVA, KOTLIN)",
      TimeDuration: "90 - 120 days",
      CourseCode: "AMABZE07",
    },
    {
      _id: "8",
      Language: "CORE JAVA, J2EE, SPRING, HIBERNATE, WEB SERVICES",
      TimeDuration: "90 - 120 days",
      CourseCode: "AMABZE08",
    },
    {
      _id: "9",
      Language: "C, ASP.NET, MVC, JAVASCRIPT, JQUERY",
      TimeDuration: "90 - 120 days",
      CourseCode: "AMABZE09",
    },
    {
      _id: "10",
      Language: "PYTHON",
      TimeDuration: "90 - 120 days",
      CourseCode: "AMABZE010",
    },
  ],
  clientReviewSlider: [
    {
      _id: "1",
      image: "/images/clientlogo/playberries.jpg",
      review:
        " It's been really an amazing experience with amabze technology as they have the best creative ideas and experience. We believed in them and they proved to be the best. As the output of our project is really outstanding. Within the time we felt a very helping and understanding behavior from amabze technology. With the blessings of god we Playberries Pvt ltd hope for the success of our project and and more stronger bond with Amabze family.",
      clientName: "PlayBerries",
    },
    {
      _id: "2",
      image: "/images/clientlogo/homeadict.jpg",
      review:
        "I'm Rohini  Director of HOMEADICT ensure that about amabze India , it is one of best on growing IT company in terms of website development, Making, researching, creating in various programming & Designing as well in my knowledge. Even i' m itself one of the prime customer of this company and extremely happy with their services. The way amabze dealing or attained  it's client is so well it's understanding approach and problem solving attitude as well.",
      clientName: "Homeadict",
    },
  ],

  
};

export default data;
