export const JOB_LIST_REQUEST = "JOB_LIST_REQUEST";
export const JOB_LIST_SUCCESS = "JOB_LIST_SUCCESS";
export const JOB_LIST_FAIL = "JOB_LIST_FAIL";

export const JOB_DETAILS_REQUEST = "JOB_DETAILS_REQUEST";
export const JOB_DETAILS_SUCCESS = "JOB_DETAILS_SUCCESS";
export const JOB_DETAILS_FAIL = "JOB_DETAILS_FAIL";

export const JOB_CREATE_REQUEST = "JOB_CREATE_REQUEST";
export const JOB_CREATE_SUCCESS = "JOB_CREATE_SUCCESS";
export const JOB_CREATE_FAIL = "JOB_CREATE_FAIL";
export const JOB_CREATE_RESET = "JOB_CREATE_RESET";

export const JOB_UPDATE_REQUEST = "JOB_UPDATE_REQUEST";
export const JOB_UPDATE_SUCCESS = "JOB_UPDATE_SUCCESS";
export const JOB_UPDATE_FAIL = "JOB_UPDATE_FAIL";
export const JOB_UPDATE_RESET = "JOB_UPDATE_RESET";

export const JOB_DELETE_REQUEST = "JOB_DELETE_REQUEST";
export const JOB_DELETE_SUCCESS = "JOB_DELETE_SUCCESS";
export const JOB_DELETE_FAIL = "JOB_DELETE_FAIL";
export const JOB_DELETE_RESET = "JOB_DELETE_RESET";

//---------------------------------------------------------JobApply
// export const APPLYJOB_LIST_REQUEST = "APPLYJOB_LIST_REQUEST";
// export const APPLYJOB_LIST_SUCCESS = "APPLYJOB_LIST_SUCCESS";
// export const APPLYJOB_LIST_FAIL = "APPLYJOB_LIST_FAIL";
export const JOBAPPLY_CONTACTUS_REQUEST = "JOBAPPLY_CONTACTUS_REQUEST";
export const JOBAPPLY_CONTACTUS_SUCCESS = "JOBAPPLY_CONTACTUS_SUCCESS";
export const JOBAPPLY_CONTACTUS_FAIL = "JOBAPPLY_CONTACTUS_FAIL";
//---------------------------------------------------------

