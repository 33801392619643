import React from "react";
import Header from "../Header";
import Footer from "../Footer";

export default function ThankYouScreen() {
  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div className="MAIN positionR">
        <div
          style={{ textAlign: "center", fontWeight: "700" }}
          className="ThankYou"
        >
          <h3 data-aos="fade-in">
            Thank you for contacting us, We will get back to you soon!
          </h3>
          <a href="https://amabze.com/" style={{ fontSize: "18px" }}>
            -&nbsp;amabze
          </a>
          <br />
          <br />

          <div className="MenuBtnContainer">
            <a href="/">
              <button
                className="MenuBtn"
                style={{ fontSize: "12px", fontWeight: "600" }}
              >
                HomePage &nbsp;<i className="fas fa-home"></i>
              </button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
