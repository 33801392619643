import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import BlogCards from "./BlogCards";

export default function BlogsScreen() {
  return (
    <div className="SCREEN-CONTAINER">
      <Header />
      <div
        className="MAIN backgroundcolor-f2f2f2"
        style={{ textAlign: "center" }}
      >
        <h2 className="MAIN-BOX_TITLE">Blogs</h2>
        <BlogCards />
      </div>
      <Footer />
    </div>
  );
}
